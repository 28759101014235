import TopBar from 'app/components/Layout/headers/TopBar';
import clsx from 'clsx';
import React, { useRef } from 'react';

import { makeStyles } from '@mui/styles';

import BluefitPageHeader from './BluefitPageHeader';
import BluefitPageSidebar from './BluefitPageSidebar';

const headerHeight = 168;
const toolbarHeight = 64;
const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    position: 'relative',
    flex: '1 0 auto',
    height: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  innerScroll: {
    flex: '1 1 auto',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    zIndex: 2,
    maxWidth: '100%',
    minWidth: 0,
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  header: {
    height: headerHeight,
    minHeight: headerHeight,
    display: 'flex',
    color: theme.palette.primary.contrastText,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.background.default,
  },
  topBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    pointerEvents: 'none',
  },
  /* contentCardWrapper            : {
         display : 'flex ',
         flex    : '1 1 auto',
         overflow: 'visible!important',
         minWidth: 0,
         '&.ps'  : {
             overflow: 'visible!important'
         }
     },
     contentCardWrapperInnerSidebar: {
         display                     : 'block',
         overflow                    : 'auto!important',
         '-webkit-overflow-scrolling': 'touch',
         '&.ps'                      : {
             overflow: 'hidden!important'
         },
         '& $contentCard'            : {
             borderRadius: 8
         }
     }, */
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  toolbar: {
    height: toolbarHeight,
    minHeight: toolbarHeight,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: '1 0 auto',
  },
  sidebarWrapper: {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    position: 'absolute',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
      },
    },
  },
  sidebar: {
    position: 'absolute',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        position: 'relative',
      },
    },
    width: drawerWidth,
    height: '100%',
  },
  leftSidebar: {
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderLeft: 0,
    },
  },
  rightSidebar: {},
  sidebarHeader: {
    height: headerHeight,
    minHeight: headerHeight,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  sidebarHeaderInnerSidebar: {
    backgroundColor: 'transparent',
    color: 'inherit',
    height: 'auto',
    minHeight: 'auto',
  },
  sidebarContent: {},
  backdrop: {
    position: 'absolute',
  },
}));

interface IRef {
  toggleSidebar: () => void;
}

interface IProps {
  header?: JSX.Element;
  content: JSX.Element;
  drawer?: JSX.Element;
  sidebarInner?: boolean;
  innerScroll?: boolean;
  leftSidebarHeader?: JSX.Element;
  leftSidebarContent?: JSX.Element;
  leftSidebarVariant?: JSX.Element;
  rightSidebarHeader?: JSX.Element;
  rightSidebarContent?: JSX.Element;
  rightSidebarVariant?: JSX.Element;
  contentToolbar?: JSX.Element;
  isFixedHeader?: boolean;
  classes?: any;
  isNewLayout?: boolean;
  rightSidebar?: React.ReactNode;
  hideTopBar?: boolean;
}

const FusePageSimple = React.forwardRef((props: IProps, ref) => {
  const leftSidebarRef = useRef<IRef>(null);
  const rightSidebarRef = useRef<IRef>(null);
  const rootRef = useRef(null);
  const classes = useStyles({ classes: props?.classes });

  React.useImperativeHandle(ref, () => ({
    rootRef,
    toggleLeftSidebar: () => {
      leftSidebarRef.current?.toggleSidebar();
    },
    toggleRightSidebar: () => {
      rightSidebarRef.current?.toggleSidebar();
    },
  }));

  return (
    <div
      className={clsx(classes.root, props.innerScroll && classes.innerScroll)}
      ref={rootRef}
    >
      {!props.isNewLayout && <div className={clsx(classes.header, classes.topBg)} />}

      <div
        className={clsx(
          'flex flex-auto flex-col container z-10 h-full',
          props.isNewLayout && 'bg-paper overflow-hidden',
        )}
      >
        {!props.hideTopBar && <TopBar />}

        {props.isNewLayout
          ? props.header && props.sidebarInner && <div>{props.header}</div>
          : props.header &&
            props.sidebarInner && (
              <BluefitPageHeader
                header={props.header}
                classes={classes}
              />
            )}

        <div className={clsx(classes.wrapper, props.isFixedHeader && 'min-h-0', props.isNewLayout && 'pb-64')}>
          {(props.leftSidebarHeader || props.leftSidebarContent) && (
            <BluefitPageSidebar
              position="left"
              header={props.leftSidebarHeader}
              content={props.leftSidebarContent}
              variant={props.leftSidebarVariant || 'permanent'}
              innerScroll={props.innerScroll}
              sidebarInner={props.sidebarInner}
              classes={classes}
              ref={leftSidebarRef}
              rootRef={rootRef}
            />
          )}

          {props.contentToolbar && props.isFixedHeader && (
            <div className={clsx(classes.toolbar, 'absolute left-0 right-0')}>{props.contentToolbar}</div>
          )}

          <div
            className={clsx('contentWrapper', classes.contentWrapper)}
            style={{}}
          >
            {props.header && !props.sidebarInner && (
              <BluefitPageHeader
                header={props.header}
                classes={classes}
              />
            )}

            {props.contentToolbar && !props.isFixedHeader && (
              <div className={classes.toolbar}>{props.contentToolbar}</div>
            )}

            {props.drawer && <div>{props.drawer}</div>}

            {props.content &&
              (!props.isNewLayout ? (
                <div className={classes.content}>{props.content}</div>
              ) : (
                <div className={clsx(classes.content, 'mb-40')}>
                  <div className="h-full flex-1 order-2 sm:order-1">{props.content}</div>
                  {props.rightSidebar && (
                    <div
                      className={clsx(
                        'order-1 sm:order-2 w-full sm:w-288 lg:w-320 h-full mb-16 sm:mb-0',
                        classes.rightSidebar,
                      )}
                    >
                      {props.rightSidebar}
                    </div>
                  )}
                </div>
              ))}
          </div>

          {(props.rightSidebarHeader || props.rightSidebarContent) && (
            <BluefitPageSidebar
              position="right"
              header={props.rightSidebarHeader}
              content={props.rightSidebarContent}
              variant={props.rightSidebarVariant || 'permanent'}
              innerScroll={props.innerScroll}
              sidebarInner={props.sidebarInner}
              classes={classes}
              ref={rightSidebarRef}
              rootRef={rootRef}
            />
          )}
        </div>
      </div>
    </div>
  );
});

FusePageSimple.defaultProps = {};

export default React.memo(FusePageSimple);
