import Header from 'app/components/Layout/headers/blank-header-v2';
import ArticleForm from 'app/components/articles/form';
import ControlTab from 'app/components/cores/control-tab';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import Loading from 'app/components/cores/loading';
import { useCreateArticle } from 'app/hooks/react-query/articles';
import useShowMessage from 'app/hooks/use-show-message';
import clsx from 'clsx';
import { Paths } from 'constants/paths';
import { formatOutputAttributes } from 'domains/articles.domain';
import { showFormError } from 'domains/form.domain';
import { TArticle, TError } from 'models';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon, Tabs, Typography } from '@mui/material';

const schema = yup
  .object({
    title: yup.string().required('Please enter the article title'),
    content: yup.object().nullable(true),
    mediaAttributes: yup.object({
      id: yup.number(),
    }),
    articleTagsAttributes: yup.array().of(
      yup.object({
        tagId: yup.number(),
      }),
    ),
    articleRolesAttributes: yup.array().of(
      yup.object({
        roleId: yup.number(),
      }),
    ),
    articleLocationsAttributes: yup.array().of(
      yup.object({
        locationId: yup.number(),
      }),
    ),
  })
  .required();

function New() {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const form = useForm({ resolver: yupResolver(schema) });
  const { mutate: createArticle, isLoading: isCreatingArticle, error } = useCreateArticle();
  const { showError, showSuccess } = useShowMessage();
  const articlePreviewData = form?.getValues() as TArticle;

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    event.preventDefault();
    setTabValue(value);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleDraft = (data: any) => {
    save(data, 'draft');
  };

  const handlePublish = (data: any) => {
    save(data, 'published');
  };

  const save = (data: any, status?: 'draft' | 'published') => {
    const {
      articleTagsAttributes,
      articleRolesAttributes,
      articleLocationsAttributes,
      articleLocationCategoriesAttributes,
      ...rest
    } = data;
    const payload = {
      ...rest,
      status,
      articleTagsAttributes: formatOutputAttributes(articleTagsAttributes, 'tagId'),
      articleRolesAttributes: formatOutputAttributes(articleRolesAttributes, 'roleId'),
      articleLocationsAttributes: formatOutputAttributes(articleLocationsAttributes, 'locationId'),
      articleLocationCategoriesAttributes: formatOutputAttributes(
        articleLocationCategoriesAttributes,
        'locationCategoryId',
      ),
    };

    createArticle(payload, {
      onSuccess() {
        history.replace(Paths.article);
        showSuccess('Article has been successfully created');
      },
      onError() {
        showError('Could not create article');
      },
    });
  };

  useEffect(() => {
    const errorMessage = form.formState.errors?.title?.message;
    if (!errorMessage) return;
    showError(errorMessage);
  }, [form.formState.errors, showError]);

  useEffect(() => {
    showFormError(form, error as TError);
  }, [form, error]);

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
        wrapper: 'relative',
        content: clsx(tabValue === 0 ? 'bg-white' : 'bg-paper'),
      }}
      header={
        <Header
          leftHeader={
            <Typography
              className="flex items-center uppercase sm:mb-12 text-secondaryMain"
              role="button"
              onClick={handleBackClick}
              color="inherit"
              variant="button"
            >
              <div className="flex flex-row">
                <Icon
                  role="button"
                  className="mt-4 text-18"
                >
                  arrow_back_ios
                </Icon>
                <div className="flex flex-col">
                  <Typography className="ml-16 text-18 font-600">Add New Article</Typography>
                </div>
              </div>
            </Typography>
          }
          rightHeader={
            <div className="flex flex-row">
              {isCreatingArticle ? (
                <div>
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap"
                    onClick={handleCancel}
                    btnTitle="Cancel"
                  />
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant="outlined"
                    onClick={form.handleSubmit(handleDraft)}
                    btnTitle="Draft"
                  />
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant="contained"
                    onClick={form.handleSubmit(handlePublish)}
                    btnTitle="Publish"
                  />
                </div>
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full h-48 relative' }}
        >
          <ControlTab
            className="h-48 normal-case font-400"
            label="Editor"
          />
          <ControlTab
            className="h-48 normal-case font-400"
            label="Settings"
          />
          <ControlTab
            className="h-48 normal-case font-400"
            label="Preview"
          />
        </Tabs>
      }
      content={
        <>
          <FormProvider {...form}>
            <ArticleForm
              tabIndex={tabValue}
              article={articlePreviewData}
            />
          </FormProvider>
          <Loading
            visible={isCreatingArticle}
            opacity={true}
          />
        </>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default New;
