import { Paths } from 'constants/paths';
import { TService } from 'models';

import _ from '@lodash';

export function updateServices(services: TService[]) {
  const nextServices = _.cloneDeep(services) || [];

  return _.chain(nextServices)
    .orderBy('order')
    .map((service: TService) => ({
      ...service,
      url: service.kind === 'hris_my_roster' ? `${service.url}${Paths.myShift}` : service.url,
    }))
    .value();
}

export const generateAvailableServices = (
  serviceCheckingList: string[],
  services: TService[],
  mobileServices: { enableWeb: boolean; service: string }[],
) => {
  if (_.isEmpty(services)) return;
  if (_.isEmpty(mobileServices)) return services;

  const isEmptyCheckingList = _.isEmpty(serviceCheckingList);
  const enabledMobileServices = _.mapValues(_.keyBy(mobileServices, 'service'), 'enableWeb') || {};
  const clonedServices = _.cloneDeep(services);

  if (isEmptyCheckingList) {
    return _.filter(clonedServices, (service: TService) => !!enabledMobileServices[service.kind]);
  }

  return _.filter(clonedServices, (service: TService) => {
    if (serviceCheckingList.includes(service.kind)) {
      return !!enabledMobileServices[service.kind];
    }
    return true;
  });
};
