import { ControlledEditorJs } from 'app/components/cores/editor-js';
import { ControlledFileUpload } from 'app/components/cores/file-upload';
import FormContentBox from 'app/components/cores/form/content-box';
import RoleIcon from 'app/components/cores/icons/role';
import { ControlledInput } from 'app/components/cores/input';
import clsx from 'clsx';
import { TArticle } from 'models';
import { useFormContext } from 'react-hook-form';

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { makeStyles } from '@mui/styles';

import FeaturedImage from './featured-image';
import LocationCategorySelect from './location-category-select';
import LocationSelect from './location-select';
import ArticlePreview from './preview';
import RoleSelect from './role-select';
import TagSelect from './tag-select';

const acceptFiles = {
  'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
};

type Props = {
  tabIndex: number;
  isCreatedArticle?: boolean;
  article?: TArticle;
};

function ArticleForm({ tabIndex, isCreatedArticle, article }: Props) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <>
      <div className={clsx(tabIndex !== 0 && 'hidden')}>
        <div className={clsx('w-full mx-auto px-64', classes.container)}>
          <div className="py-16 sm:pt-24 min-h-320">
            <ControlledFileUpload
              name="mediaAttributes"
              control={control}
              acceptFiles={acceptFiles}
              component={<FeaturedImage />}
            />
          </div>

          <ControlledInput
            placeholder="Article Title"
            name="title"
            control={control}
            InputProps={{
              classes: {
                root: 'pl-0',
                input: clsx('text-40 font-700 pl-0 min-h-64 pt-8', classes.title),
                notchedOutline: classes.noBorder,
              },
            }}
            multiline={true}
            showErrorMessage={false}
          />

          <ControlledEditorJs
            placeholder="Begin writing your article..."
            name="content"
            control={control}
            forceUpdate={isCreatedArticle}
          />
        </div>
      </div>

      <div className={clsx('p-16 sm:p-24 w-full h-full bg-paper', tabIndex !== 1 && 'hidden')}>
        {/* tag */}
        <FormContentBox
          title="Tags"
          icon={<LocalOfferOutlinedIcon className="text-20" />}
        >
          <TagSelect />
        </FormContentBox>

        {/* location category */}
        <FormContentBox
          title="Location Categories"
          icon={<LocationOnOutlinedIcon className="text-22" />}
        >
          <LocationCategorySelect />
        </FormContentBox>

        {/* location */}
        <FormContentBox
          title="Locations"
          icon={<LocationOnOutlinedIcon className="text-22" />}
        >
          <LocationSelect />
        </FormContentBox>

        {/* role */}
        <FormContentBox
          title="Roles"
          icon={<RoleIcon className="text-22" />}
        >
          <RoleSelect />
        </FormContentBox>
      </div>

      <div className={clsx(tabIndex !== 2 && 'hidden')}>
        <ArticlePreview article={article} />
      </div>
    </>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1080,
  },
  title: {
    lineHeight: '40px',
  },
  noBorder: {
    border: '0 !important',
  },
}));

export default ArticleForm;
