import clsx from 'clsx';
import { RenderFn } from 'editorjs-blocks-react-renderer';

const Video: RenderFn<{}> = ({ data, className = '' }: any) => {
  return (
    <div className={clsx(className, data?.stretched && 'editor-viewer__video--stretched')}>
      <video
        controls={true}
        src={data.file?.url}
      />
      {data.caption && <figcaption>{data.caption}</figcaption>}
    </div>
  );
};

export default Video;
