import TagItem from 'app/components/tags/item';
import clsx from 'clsx';
import { TTag } from 'models';
import { useMemo } from 'react';
import { formatDate } from 'utils/date';

import _ from '@lodash';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import ImagePlaceholder from './image-placeholder';

type Props = {
  item: any;
  selectedTag?: TTag | null;
  onClick?: () => void;
};

function NewsItem({ item, selectedTag, onClick }: Props) {
  const classes = useStyles();
  const image = item.media?.objectFiles?.[0]?.url;

  const summary = useMemo(() => {
    const paragraphs = _.filter(item?.content?.blocks, { type: 'paragraph' });
    return _.map(paragraphs, (p: any) => p.data?.text)?.join(' ');
  }, [item]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx('bg-white cursor-pointer duration-300 h-full', classes.root)}
      onClick={onClick}
    >
      {image ? (
        <div className="w-full bg-gray-300 h-200 sm:h-288 xl:h-320 overflow-hidden">
          <img
            alt="cover"
            src={image}
            className={clsx('w-full h-full object-cover duration-300', classes.imageTarget)}
          />
        </div>
      ) : (
        <div className="shadow h-200 sm:h-288 xl:h-320">
          <ImagePlaceholder
            className={classes.imageTarget}
            title={item.title}
          />
        </div>
      )}

      <div className="p-24">
        <Typography className={clsx('text-18 lg:text-24 font-700 uppercase h-64 mb-8', classes.text, classes.title)}>
          {item.title}
        </Typography>

        <Typography
          className={clsx('text-13 font-400 h-40 capitalize mb-24', classes.text, classes.summary)}
          dangerouslySetInnerHTML={{ __html: summary }}
        />

        <div className="flex items-start">
          <Typography className="text-11 font-500 text-secondaryLight w-80">
            {formatDate(item.publishedAt, 'DD.MM.YYYY')}
          </Typography>
          <div className="flex-1 -mt-4">
            {item.articleTagsAttributes?.map((tag: any) => (
              <TagItem
                key={tag.id}
                item={tag}
                highlight={selectedTag?.id === tag.tagId}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
      '& $imageTarget': {
        transform: 'scale(1.05)',
      },
    },
  },
  imageTarget: {},
  text: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  summary: {
    '& *': {
      fontFamily: 'inherit !important',
      fontSize: '13px !important',
      fontWeight: '400 !important',
      fontStyle: 'normal !important',
      color: `${theme.palette.secondary.main} !important`,
      backgroundColor: 'unset !important',
    },
  },
  title: {
    lineHeight: '30px',
  },
}));
export default NewsItem;
