import clsx from 'clsx';
import { useEffect, useState } from 'react';

import _ from '@lodash';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { makeStyles } from '@mui/styles';

function ScrollTopButton() {
  const classes = useStyles();
  const [isShowButton, setShowButton] = useState(false);

  useEffect(() => {
    const contentWrapper = document.querySelector('.contentWrapper') as any;
    if (!contentWrapper) return;

    const handleScroll = _.throttle((event: any) => {
      const scrollTop = event.target?.scrollTop || 0;
      setShowButton(scrollTop >= 500);
    }, 500);

    contentWrapper?.addEventListener('scroll', handleScroll);

    return () => {
      contentWrapper?.removeEventListener('scroll', handleScroll);
    };
  }, [isShowButton]);

  const handleScrollToTop = () => {
    const contentWrapper = document.querySelector('.contentWrapper') as any;
    contentWrapper?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!isShowButton) return null;

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx(
        'flex items-center justify-center w-40 h-40 rounded bg-primaryLight hover:bg-gray-500 duration-300',
        classes.root,
      )}
      onClick={handleScrollToTop}
    >
      <VerticalAlignTopIcon className="text-20" />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 16,
    right: 24,
    '@media(min-width: 600px)': {
      right: 224,
    },
    '@media(min-width: 960px)': {
      right: 236,
      bottom: 28,
    },
    '@media(min-width: 1280px)': {
      right: 268,
    },
  },
}));

export default ScrollTopButton;
