import { useFetchServices } from 'app/hooks/react-query/services';
import { generateAvailableServices } from 'domains/service.domain';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const serviceCheckingList = ['task']; // leave empty will check all services

export function useServices() {
  const { data: services, isLoading } = useFetchServices();
  const mobileServices = useSelector(({ auth }) => auth.user.mobileServices);

  const nextServices = useMemo(
    () => generateAvailableServices(serviceCheckingList, services, mobileServices),
    [services, mobileServices],
  );

  return !services
    ? {
        data: undefined,
        isLoading,
      }
    : {
        data: nextServices,
        isLoading,
      };
}
