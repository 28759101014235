import Image from '@editorjs/image';

class CustomImageTool extends Image {
  constructor(props: any) {
    super(props);
    this.tunes.actions = this.tunes.actions.map((a: any) => {
      return {
        ...a,
        action: a.action.bind(this),
      };
    });
  }
}

export default CustomImageTool;
