import NewsIcon from 'app/components/cores/icons/news';
import React from 'react';

const icons = {
  news: NewsIcon,
};

function FuseNavVerticalIcon(props) {
  const { name, ...other } = props;
  const Icon = icons[name];

  if (!name || !Icon) return null;

  return <Icon {...other} />;
}

export default FuseNavVerticalIcon;
