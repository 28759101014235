import { ControlledSelect } from 'app/components/cores/select';
import { useFetchLocationCategories } from 'app/hooks/react-query/location-categories';
import useSelect from 'app/hooks/use-select';
import _ from 'lodash';
import { TLocationCategory } from 'models';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ActionMeta } from 'react-select';

function LocationCategorySelect() {
  const { control } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data, isLoading } = useFetchLocationCategories({
    page: pageIndex,
    per_page: pageSize,
    search: searchText,
    with_salt: false,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const handleChange = (
    currentValue: TLocationCategory[],
    previousValue: TLocationCategory[],
    actionPayload: ActionMeta<TLocationCategory>,
    onChange: (newValue: TLocationCategory[]) => void,
  ) => {
    if (actionPayload?.action === 'select-option') {
      const nextValue = _.cloneDeep(previousValue);
      const selectedOption = actionPayload?.option;
      const existedSelectedOption = _.find(nextValue, { id: selectedOption?.externalId, _destroy: true });

      if (existedSelectedOption) {
        existedSelectedOption._destroy = undefined;
        onChange(nextValue);
        return;
      }
    }

    onChange(currentValue);
  };

  const formatOptions = (options: TLocationCategory[]) => {
    return _.map(options, option => ({
      id: option.externalId,
      name: option.name,
    }));
  };

  return (
    <ControlledSelect
      isMulti={true}
      menuPlacement="auto"
      placeholder="Location Categories"
      allOptionsPlaceholder={true}
      name="articleLocationCategoriesAttributes"
      control={control}
      options={formatOptions(options)}
      getOptionValue={(option: any) => option.id}
      getOptionLabel={(option: any) => option.name}
      overrideChangeFunction={handleChange}
      onInputChange={handleSearch}
      onMenuScrollToBottom={handleLoadMore}
    />
  );
}

export default LocationCategorySelect;
