import clsx from 'clsx';
import { RenderFn } from 'editorjs-blocks-react-renderer';

const TextAlignment: RenderFn<{}> = ({ data }: any) => {
  const getAlignmentClassName = (alignment?: string) => {
    if (!alignment) return '';
    if (alignment === 'left') return 'ce-paragraph--left';
    if (alignment === 'right') return 'ce-paragraph--right';
    if (alignment === 'center') return 'ce-paragraph--center';
  };

  return (
    <div
      className={clsx('ce-paragraph py-4', getAlignmentClassName(data?.alignment))}
      dangerouslySetInnerHTML={{ __html: data?.text || '' }}
    />
  );
};

export default TextAlignment;
