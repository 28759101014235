import Button from '../blocks/button';
import Checklist from '../blocks/checklist';
import Columns from '../blocks/columns';
import Image from '../blocks/image';
import Raw from '../blocks/raw';
import SimpleImage from '../blocks/simple-image';
import TextAlignment from '../blocks/text-alignment';
import Video from '../blocks/video';

export const BLOCK_CONFIGS = {
  code: {
    className: 'bg-grey-200',
  },
  delimiter: {
    className: 'editor-viewer__delimiter',
  },
  embed: {
    className: 'max-w-full border-0 mx-auto',
  },
  header: {
    className: 'ce-header',
  },
  image: {
    className: 'editor-viewer__image mt-16 mb-32 text-center italic flex flex-col items-center justify-center',
    actionsClassNames: {
      stretched: 'editor-viewer__image--stretched',
      withBorder: 'border border-2',
      withBackground: 'bg-paper editor-viewer__image--withBackground',
    },
  },
  video: {
    className: 'editor-viewer__video mt-16 mb-32 text-center italic flex flex-col items-center justify-center',
    actionsClassNames: {
      stretched: 'editor-viewer__video--stretched',
      withBorder: 'border border-2',
      withBackground: 'p-2 bg-paper',
    },
  },
  list: {
    className: 'list-inside mb-8 editor-viewer__list ml-24',
  },
  paragraph: {
    className: 'ce-paragraph cdx-block',
    actionsClassNames: {
      alignment: 'text-{alignment}', // This is a substitution placeholder: left or center.
    },
  },
  quote: {
    className: 'py-3 px-5 italic font-serif mb-16 editor-viewer__blockquote',
  },
  table: {
    className: 'table-fixed w-full border-collapse mb-16',
  },
  simpleImage: {
    className: 'editor-viewer__image mt-16 mb-32 text-center italic flex flex-col items-center justify-center',
  },
  columns: {
    className: 'editor-viewer__columns flex flex-col sm:flex-row space-x-8',
  },
  raw: {
    className: 'editor-viewer__raw my-16',
  },
  button: {
    className: 'editor-viewer__button py-4 text-white',
  },
};

export const RENDERERS = {
  checklist: Checklist,
  simpleImage: SimpleImage,
  paragraph: TextAlignment,
  video: Video,
  columns: Columns,
  image: Image,
  raw: Raw,
  button: Button,
};
