import clsx from 'clsx';
import { RenderFn } from 'editorjs-blocks-react-renderer';
import { v4 as uuidv4 } from 'uuid';

const Checklist: RenderFn<{
  items: string[];
}> = ({ data, className = '' }: any) => {
  return (
    <div className="mb-16 font-sans">
      {data?.items.map((item: any) => (
        <p
          key={uuidv4()}
          className={clsx('editor-viewer__checklist', className)}
        >
          <input
            type="checkbox"
            checked={item.checked}
            readOnly
          />
          <span dangerouslySetInnerHTML={{ __html: item?.text }} />
        </p>
      ))}
    </div>
  );
};

export default Checklist;
