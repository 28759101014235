import clsx from 'clsx';
import React from 'react';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  box: {
    background: theme.palette.background.default,
    borderRadius: '0.5rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    marginBottom: 16,
  },
  title: {
    color: theme.palette.secondary.light,
  },
}));

type Props = {
  title?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  rightHeader?: React.ReactNode;
  disableMarginChildren?: boolean;
};

function FormContentBox({ title, icon, children, rightHeader, disableMarginChildren }: Props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.box, 'flex flex-col pt-8 pb-16 pr-16')}>
      {title ? (
        <>
          <div className="flex flex-row ml-8">
            <div className="flex items-center justify-center w-40 h-40">{icon}</div>
            <div className="ml-8 flex flex-col justify-center">
              <Typography className={clsx(classes.title, 'text-13 font-400')}>{title}</Typography>
            </div>
            {rightHeader && <div className="flex items-center ml-auto">{rightHeader}</div>}
          </div>
          <div className={!disableMarginChildren ? 'ml-56' : ''}>{children}</div>
        </>
      ) : (
        <div className="flex flex-row ml-8 items-center justify-center">
          {icon && <div className="flex items-center justify-center w-40 h-40">{icon}</div>}
          <div className={clsx(!disableMarginChildren ? 'ml-56' : '', 'w-full')}>{children}</div>
        </div>
      )}
    </div>
  );
}

export default FormContentBox;
