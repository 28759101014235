import _ from 'lodash';
import { TError, TErrorDetail } from 'models';

export function showFormError(form: any, error: TError) {
  const nextError = _.cloneDeep(error) as TError;
  const errors = nextError?.errors;
  if (!errors?.length) return;

  _.forEach(errors, (error: TErrorDetail, index: number) => {
    const field = error?.field;
    const message = error?.message?.[0];
    const errorMessage = message ? `The ${field} ${message}` : `Please enter the valid ${field}`;
    const config = { shouldFocus: index === 0 };
    form?.setError(field, { type: 'custom', message: errorMessage }, config);
  });
}
