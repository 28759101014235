import EditorViewer from 'app/components/cores/editor-js/editor-viewer';
import TagItem from 'app/components/tags/item';
import clsx from 'clsx';
import { TArticle, TArticleTagAttribute } from 'models';
import { formatDate } from 'utils/date';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

type Props = {
  article?: TArticle;
};

function ArticlePreview({ article }: Props) {
  const classes = useStyles();
  const image = article?.mediaAttributes?.file?.url;

  return (
    <div className={clsx('h-full bg-paper pt-24', classes.root)}>
      <div className="bg-white shadow h-full">
        <div className="lg:px-80 lg:pt-80 mb-24">
          {/* primary image */}
          {image && (
            <img
              alt="cover"
              src={image}
              className="w-full object-cover"
            />
          )}
        </div>

        <div className="px-24 pb-24 lg:px-80 lg:pb-80">
          {/* content */}
          <Typography className="text-24 lg:text-40 font-700 uppercase pb-24">{article?.title}</Typography>

          {/* tags */}
          <div className="mb-16">
            {article?.articleTagsAttributes?.map((tag: TArticleTagAttribute) => (
              <TagItem
                key={tag.id}
                item={{
                  ...tag,
                  tagName: tag.name,
                }}
              />
            ))}
          </div>

          {/* date */}
          <div className="mb-16">
            <Typography className="text-11 font-500 text-secondaryLight">
              {formatDate(article?.publishedAt, 'DD.MM.YYYY')}
            </Typography>
          </div>

          {/* content */}
          <EditorViewer content={article?.content} />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    '@media(min-width: 960px)': {
      paddingLeft: 144,
      paddingRight: 144,
    },
    '@media(min-width: 1280px)': {
      paddingLeft: 160,
      paddingRight: 160,
    },
  },
}));

export default ArticlePreview;
