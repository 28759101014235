/* eslint-disable */
import queryClient from 'app/configs/query-client';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { StyledEngineProvider } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName, jssPreset } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './configs/routesConfig';
import store from './store';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById('jss-insertion-point') as any,
});

const generateClassName = createGenerateClassName();

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <StyledEngineProvider injectFirst>
        <StylesProvider
          jss={jss}
          generateClassName={generateClassName}
        >
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Auth>
                  <Router history={history}>
                    <FuseAuthorization>
                      <FuseTheme>
                        <FuseLayout />
                      </FuseTheme>
                    </FuseAuthorization>
                  </Router>
                </Auth>
              </LocalizationProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </Provider>
        </StylesProvider>
      </StyledEngineProvider>
    </AppContext.Provider>
  );
}

export default App;
