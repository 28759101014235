import { useEffect, useRef, useState } from 'react';

type Params = {
  data?: any;
  isLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  setSearchText: (value: string) => void;
  setPageIndex: (value: number) => void;
  isReactSelect?: boolean;
};

function useSelect({
  data,
  isLoading,
  pageIndex,
  pageSize = 50,
  setSearchText,
  setPageIndex,
  isReactSelect = true,
}: Params) {
  const firstLoad = useRef(false);
  const canLoadMore = useRef(true);
  const searchText = useRef('');
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (!data || !canLoadMore.current) return;
    if (data?.length < pageSize) {
      canLoadMore.current = false;
    }
    if (!firstLoad.current) {
      firstLoad.current = true;
      return setOptions(data);
    }
    setOptions((prevData: any) => [...prevData, ...data]);
  }, [data, pageSize]);

  const handleSearch = (value: string) => {
    setPageIndex(1);
    firstLoad.current = false;
    canLoadMore.current = true;
    searchText.current = value;
    setSearchText?.(value);
  };

  const handleReactSelectSearch = (value: string, actionPayload: any) => {
    if (actionPayload.action === 'input-change' || (!!searchText.current && actionPayload.action === 'input-blur')) {
      handleSearch(value);
    }
  };

  const handleLoadMore = () => {
    if (!canLoadMore.current || isLoading) return;
    setPageIndex(pageIndex + 1);
  };

  const handleReset = () => {
    setPageIndex(1);
    firstLoad.current = false;
    canLoadMore.current = true;
  };

  return {
    options,
    handleSearch: isReactSelect ? handleReactSelectSearch : handleSearch,
    handleLoadMore,
    handleReset,
  };
}

export default useSelect;
