import { uploadMedia } from 'app/hooks/react-query/media';
import Paragraph from 'editorjs-paragraph-with-alignment';
import ColorPlugin from 'editorjs-text-color-plugin';

import CheckList from '@editorjs/checklist';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import InlineCode from '@editorjs/inline-code';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Raw from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Table from '@editorjs/table';
import _ from '@lodash';
import Video from '@weekwood/editorjs-video';

import { replaceImageAction } from '../actions/image';
import CustomButton from '../customs/button';
import CustomColumnsTool from '../customs/columns';
import CustomEmbedTool from '../customs/embed';
import CustomHyperLinkTool from '../customs/hyperlink';
import CustomImageTool from '../customs/image';

async function uploadByFile(file: File) {
  try {
    const res = await uploadMedia(file);
    return {
      success: 1,
      file: res?.file,
    };
  } catch (error) {
    return {
      success: 0,
    };
  }
}

export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    inlineToolbar: ['link', 'marker'],
    config: {
      levels: [1, 2, 3, 4, 5],
    },
  },
  button: {
    class: CustomButton,
    inlineToolbar: false,
    config: {
      css: {
        btnColor: 'button-tool',
      },
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  checklist: {
    class: CheckList,
    inlineToolbar: true,
  },
  image: {
    class: CustomImageTool,
    config: {
      uploader: {
        uploadByFile,
      },
      actions: [replaceImageAction],
    },
  },
  video: {
    class: Video,
    config: {
      uploader: {
        uploadByFile,
      },
      player: {
        controls: true,
        autoplay: false,
      },
    },
  },
  table: Table,
  quote: Quote,
  raw: Raw,
  code: Code,
  embed: {
    class: CustomEmbedTool,
    config: {
      services: {
        youtube: true,
        vimeo: {
          regex:
            /(\s)*(?:https?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?(\/)?([a-zA-Z0-9_-]+)?(\s)*/i,
          embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>',
          html: `<iframe title="vimeo-player" frameborder="0" allowfullscreen></iframe>`,
          id: (groups: any) => groups?.filter((item: string) => !!item && item !== '/')?.join('/?h='),
        },
      },
    },
  },
  marker: Marker,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  hyperlink: {
    class: CustomHyperLinkTool,
    config: {
      shortcut: 'CMD+L',
      target: '_blank',
      rel: 'noreferrer',
      availableTargets: ['_blank', '_self'],
      availableRels: ['noreferrer', 'noopener'],
      validate: false,
    },
  },
  Color: {
    class: ColorPlugin,
    config: {
      colorCollections: [
        '#EC7878',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#0070FF',
        '#03A9F4',
        '#00BCD4',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFF',
      ],
      defaultColor: '#FF1300',
      type: 'text',
    },
  },
  Marker: {
    class: ColorPlugin,
    config: {
      type: 'marker',
    },
  },
};

const inlineToolsForColumn = _.pick(EDITOR_JS_TOOLS, [
  'paragraph',
  'marker',
  'inlineCode',
  'hyperlink',
  'Color',
  'Marker',
]);

export const EDITOR_JS_MAIN_TOOLS = {
  ...EDITOR_JS_TOOLS,
  columns: {
    class: CustomColumnsTool,
    config: {
      tools: {
        ...inlineToolsForColumn,
        image: {
          class: CustomImageTool,
          config: {
            uploader: {
              uploadByFile,
            },
            actions: [replaceImageAction],
          },
        },
        simpleImage: SimpleImage,
      },
    },
  },
};
