import { useMutation } from 'react-query';
import api from 'utils/api';

type UploadProgressType = (progressEvent: any) => void;

export async function uploadMedia(file: File, onUploadProgress?: UploadProgressType) {
  const data = new FormData();
  data.append('media[files][]', file, file.name);

  const response = await api.post('/api/media', data, {
    headers: { 'Content-Type': `multipart/form-data` },
    onUploadProgress,
  });
  return response.data;
}

export function useUploadMedia() {
  return useMutation(({ file, onUploadProgress }: { file: File; onUploadProgress?: UploadProgressType }) =>
    uploadMedia(file, onUploadProgress),
  );
}

export default {};
