import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function Role(props: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18 18"
      width="18"
      height="18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0526 6.43421C11.0526 7.91724 9.85142 9.11842 8.36839 9.11842C6.88536 9.11842 5.68418 7.91724 5.68418 6.43421C5.68418 4.95118 6.88536 3.75 8.36839 3.75C9.85142 3.75 11.0526 4.95118 11.0526 6.43421ZM9.71052 6.43422C9.71052 5.69606 9.10657 5.09211 8.36842 5.09211C7.63026 5.09211 7.02631 5.69606 7.02631 6.43422C7.02631 7.17237 7.63026 7.77632 8.36842 7.77632C9.10657 7.77632 9.71052 7.17237 9.71052 6.43422ZM4.34211 13.1447C4.47632 12.722 6.06671 12.0174 7.67053 11.8429L9.03947 10.5008C8.77776 10.474 8.58316 10.4605 8.36842 10.4605C6.57671 10.4605 3 11.3597 3 13.1447V14.4868H9.03947L7.69737 13.1447H4.34211ZM11.3681 12.9233L14.8106 9.45396L15.75 10.4001L11.3681 14.8224L9.03951 12.4737L9.97898 11.5275L11.3681 12.9233Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(Role);
