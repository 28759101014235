import Blocks, { RenderFn } from 'editorjs-blocks-react-renderer';

import { BLOCK_CONFIGS, RENDERERS } from '../config/block';

const Columns: RenderFn<{}> = ({ data, className = '' }: any) => {
  return (
    <div className={className}>
      {data?.cols?.map((col: any, index: number) => (
        <div
          key={index}
          className="flex-1"
        >
          <Blocks
            data={col}
            config={BLOCK_CONFIGS}
            renderers={RENDERERS}
          />
        </div>
      ))}
    </div>
  );
};

export default Columns;
