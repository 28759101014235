import ErrorMessage from 'app/components/cores/form/error-message';
import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      width: 120,
      fontWeight: 400,
      fontSize: 13,
      color: theme.palette.secondary.main,
      background: theme.palette.background.paper,
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    buttonSelected: {
      background: theme.palette.primary.main,
      color: 'white',
    },
    label: {
      color: theme.palette.secondary.main,
      fontSize: 13,
    },
  }),
);

type Props = {
  name: string;
  control: Control;
  rules?: any;
  showErrorMessage?: boolean;
};

function StatusBox({ name, control, rules, showErrorMessage }: Props) {
  const classes = useStyles();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (key: any) => {
    onChange?.(key);
  };

  const statuses = {
    active: 'Active',
    inactive: 'Inactive',
  };
  return (
    <>
      <div className="bg-white rounded-4 shadow">
        <div className="p-12 px-12 flex justify-between">
          <div className="flex items-center">
            <Typography className={clsx(classes.label)}>Status</Typography>
          </div>
          <div className="flex">
            {statuses &&
              Object.entries(statuses).map(([key, v]) => (
                <Button
                  key={key}
                  className={clsx(
                    classes.button,
                    'whitespace-no-wrap normal-case mx-4',
                    value === key && classes.buttonSelected,
                  )}
                  color="primary"
                  onClick={() => handleChange(key)}
                >
                  {v}
                </Button>
              ))}
          </div>
        </div>
      </div>
      {showErrorMessage && (
        <div className="ml-10">
          <ErrorMessage message={error?.message} />
        </div>
      )}
    </>
  );
}

export default StatusBox;
