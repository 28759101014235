import React from 'react';
import ReactDOM from 'react-dom/client';
import 'typeface-montserrat';
import { isDevelopment } from 'utils/env';

import * as Sentry from '@sentry/react';

import App from './app/App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

Sentry.init({
  enabled: !isDevelopment,
  environment: process.env.REACT_APP_SENTRY_ENV || 'production',
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
