import Logo from 'app/components/cores/icons/logo';
import clsx from 'clsx';
import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import JWTLoginTab from './components/LoginForm';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
  leftSection: {
    background: theme.palette.secondary.main,
  },
  logo: {
    flexGrow: 2,
  },
  loginForm: {
    flexGrow: 2,
  },
}));

function Login() {
  const classes = useStyles();
  const [isShowLoginForm, showLoginForm] = useState(true);

  return (
    <div
      className={clsx(classes.root, 'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24')}
    >
      <Card
        className={clsx(classes.leftSection, 'flex flex-col max-w-lg items-center flex-1')}
        square
        elevation={0}
      >
        <div className={clsx(classes.logo, 'flex items-center')}>
          <Logo
            className="text-96 mr-56"
            color="primary"
          />
          <Typography className="text-white text-32">Welcome to the SALT!</Typography>
        </div>
        <CardContent className={clsx(classes.loginForm, 'flex flex-col items-center justify-start w-full max-w-320')}>
          <JWTLoginTab show={() => showLoginForm(false)} />
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
