import { useQuery } from 'react-query';
import api from 'utils/api';

const REFETCH_AFTER = 1000 * 60 * 1;

export async function getNotificationStatus() {
  try {
    const response = await api.get('/api/notifications/status');
    return response?.data;
  } catch (_) {
    return {};
  }
}

export async function fetchUserNotificationSettings(userId: number) {
  const response = await api.get(`/api/users/${userId}/notification_settings`);
  return response?.data;
}

export function useGetNotificationStatus() {
  return useQuery(['getNotificationStatus'], () => getNotificationStatus(), {
    refetchInterval: REFETCH_AFTER,
  });
}

export function useFetchUserNotificationSettings(userId: number) {
  return useQuery(['fetchUserNotificationSettings', userId], () => fetchUserNotificationSettings(userId), {
    enabled: !!userId,
  });
}
