import { useFetchArticles } from 'app/hooks/react-query/employee';
import clsx from 'clsx';
import { Paths } from 'constants/paths';
import { TArticle } from 'models';
import { formatDate } from 'utils/date';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

type Props = {
  className?: string;
};

function LatestNews({ className }: Props) {
  const classes = useStyles();
  const { data: dataArticles } = useFetchArticles({
    page: 1,
    per_page: 6,
    'q[s]': 'published_at desc',
  });
  const articles = dataArticles?.records || [];

  const getUrl = (article: TArticle) => {
    return `${Paths.news}/${article.slug || article.id}`;
  };

  if (!articles?.length) return null;

  return (
    <div className={clsx('px-24', className)}>
      <div className="flex items-center justify-between mb-16">
        <Typography className="text-11 font-400 uppercase">Latest News</Typography>
      </div>

      <div className="bg-white shadow">
        {articles?.map((article: TArticle) => (
          <a
            key={article.id}
            href={getUrl(article)}
            className={clsx(
              'bg-white border-b-1 border-gray-200 px-16 py-12 block hover:bg-gray-100 duration-300',
              classes.noUnderline,
            )}
          >
            <Typography className={clsx('text-15 lg:text-16 font-700', classes.title)}>{article.title}</Typography>
            {article?.publishedAt && (
              <Typography className="text-11 font-500 text-secondaryLight">
                {formatDate(article?.publishedAt, 'DD.MM.YYYY')}
              </Typography>
            )}
          </a>
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  noUnderline: {
    textDecoration: 'none !important',
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

export default LatestNews;
