import FileUploadIcon from '@mui/icons-material/FileUpload';
import Typography from '@mui/material/Typography';

function FeaturedImage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-288 bg-grey-50 hover:bg-grey-200 duration-300 rounded">
      <FileUploadIcon className="text-secondaryLight text-32" />
      <Typography className="text-16 mt-8">Drag and drop to upload file or browse image</Typography>
    </div>
  );
}

export default FeaturedImage;
