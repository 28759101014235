import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from '@lodash';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

function NavbarFoldedToggleButton(props) {
  const dispatch = useDispatch();
  const settings = useSelector(({ fuse }) => fuse.settings.current);

  return (
    <IconButton
      className={props.className}
      style={{ marginRight: '12px' }}
      onClick={() => {
        dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)));
      }}
      color="inherit"
    >
      {props.children}
    </IconButton>
  );
}

NavbarFoldedToggleButton.defaultProps = {
  children: <Icon className="text-20">menu</Icon>,
};

export default NavbarFoldedToggleButton;
