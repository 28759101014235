import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

type Props = {
  title?: string;
  className?: string;
};

function ImagePlaceholder({ title, className }: Props) {
  const classes = useStyles();
  const firstLetter = title?.charAt(0);

  return (
    <div className={clsx('h-full flex items-center justify-center duration-300', className)}>
      <Typography className={clsx('font-700 uppercase', classes.text)}>{firstLetter}</Typography>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 168,
  },
}));

export default ImagePlaceholder;
