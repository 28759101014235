import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import React from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className="relative z-10"
        color="default"
      >
        <Toolbar className="px-16 py-0 flex items-center">
          <Typography>BlueFit</Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default React.memo(FooterLayout1);
