/**
 * Authorization Roles
 */
const authRoles = {
  editor: ['editor'],
  employee: ['employee'],
  onlyGuest: [],
  user: ['editor', 'employee'],
};

export default authRoles;
