import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

const useShowMessage = () => {
  const dispatch = useDispatch();

  const showSuccess = (message: string) => {
    dispatch(showMessage({ message, variant: 'success' }));
  };
  const showError = (message: string) => {
    dispatch(showMessage({ message, variant: 'error' }));
  };

  return { showSuccess, showError };
};

export default useShowMessage;
