import MyAccountIcon from 'app/components/cores/icons/my-account';
import MyFacilityIcon from 'app/components/cores/icons/my-facility';
import MyMessageIcon from 'app/components/cores/icons/my-message';
import MyNewsIcon from 'app/components/cores/icons/my-news';
import MyPeopleIcon from 'app/components/cores/icons/my-people';
import MyResourcesIcon from 'app/components/cores/icons/my-resources';
import MyRosterIcon from 'app/components/cores/icons/my-roster';
import MyTaskIcon from 'app/components/cores/icons/my-task';
import MyTrainingIcon from 'app/components/cores/icons/my-training';
import SaltIcon from 'app/components/cores/icons/salt';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const icons: { [key: string]: any } = {
  sso: <SaltIcon />,
  salt: <MyFacilityIcon />,
  task: <MyTaskIcon />,
  hris_my_roster: <MyRosterIcon />,
  sso_my_messages: <MyMessageIcon />,
  hris_my_account: <MyAccountIcon />,
  hris: <MyPeopleIcon />,
  internal_lms: <MyTrainingIcon />,
  internal_lms_my_resources: <MyResourcesIcon />,
  rto_lms: <MyTrainingIcon />,
  news: <MyNewsIcon className="ml-2 text-20" />,
};

const useStyles = makeStyles((theme: any) => ({
  link: {
    textDecoration: 'none !important',
    '&:hover > p': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  linkActive: {
    '& > p': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  linkWidth: {
    minWidth: 180,
  },
  title: {
    borderColor: 'transparent',
  },
}));
type Props = {
  items?: any;
  isDesktopScreen?: boolean;
};

function Menu({ items, isDesktopScreen }: Props) {
  const classes = useStyles();

  const isActive = (kind: string) => {
    return kind === 'news';
  };

  return (
    <>
      {items?.map((item: any) => (
        <a
          key={item.order}
          href={item.url}
          target="_self"
          className={clsx(
            'inline-flex items-center whitespace-no-wrap',
            classes.link,
            isActive(item.kind) && classes.linkActive,
            isDesktopScreen ? 'px-16' : 'px-12',
            !isDesktopScreen && classes.linkWidth,
          )}
        >
          <div className="pb-4 mr-4 lg:mr-6 xl:mr-8">{icons[item.kind]}</div>
          <Typography
            className={clsx(
              'font-400 secondaryMain uppercase border-b duration-300 pb-4',
              classes.title,
              isDesktopScreen ? 'text-14' : 'text-12',
            )}
          >
            {item.name}
          </Typography>
        </a>
      ))}
    </>
  );
}

export default Menu;
