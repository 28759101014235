import DragAndDrop from 'app/components/cores/drap-and-drop';
import clsx from 'clsx';
import { TMedia } from 'models/index';
import { Accept } from 'react-dropzone';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export type FileUploadProps = {
  uploadZipFile?: boolean;
  acceptFiles: Accept;
  multiple?: boolean;
  classes?: { [key: string]: any };
  readOnly?: boolean;
  value?: TMedia;
  component?: React.ReactNode;
  onChange?: (media: TMedia) => void;
  onLoading?: (loading: boolean) => void;
  onRemove?: () => void;
  [key: string]: any;
};

const FileUpload = ({
  uploadZipFile,
  acceptFiles,
  multiple,
  classes: propClasses,
  readOnly = false,
  value,
  component,
  onChange,
  onLoading,
  onRemove,
}: FileUploadProps) => {
  const classes = useStyles({ classes: propClasses });

  const handleFileClick = (value: TMedia) => {
    if (!value?.file?.url) return;
    window.open(value?.file?.url);
  };

  const handleRemove = (event: any) => {
    event.stopPropagation();
    onRemove?.();
  };

  const isCustomComponent = !!component;
  const hasFile = !!value?.file?.url;

  return (
    <div className={clsx('flex flex-col w-full', readOnly && 'pointer-events-none')}>
      <div className="flex w-full">
        <DragAndDrop
          uploadZipFile={uploadZipFile}
          onChange={onChange}
          acceptFiles={acceptFiles}
          multiple={multiple}
          classes={{ root: clsx(classes?.dragAndDropRoot, hasFile && 'border-0') }}
          onLoading={onLoading}
        >
          {isCustomComponent ? (
            <div className="w-full cursor-pointer rounded">
              {hasFile ? (
                <div
                  className={clsx(
                    'relative flex items-center justify-center duration-300 min-h-288 bg-gray-300',
                    classes.imageContainer,
                  )}
                >
                  <img
                    src={value.file?.url}
                    alt="uploaded"
                    className="w-full object-cover rounded"
                  />
                  <div className="absolute flex items-center space-x-8">
                    <div
                      className={clsx(
                        'w-64 h-64 rounded-32 bg-black bg-opacity-75 hover:bg-opacity-100 flex items-center justify-center duration-300',
                        classes.imageUploadButton,
                      )}
                    >
                      <FileUploadIcon className="text-white" />
                    </div>
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={handleRemove}
                      className={clsx(
                        'w-64 h-64 rounded-32 bg-black bg-opacity-75 hover:bg-opacity-100 flex items-center justify-center duration-300',
                        classes.imageUploadButton,
                      )}
                    >
                      <RemoveOutlinedIcon className="text-white" />
                    </div>
                  </div>
                </div>
              ) : (
                component
              )}
            </div>
          ) : (
            <div className="flex flex-row h-64 w-full items-center bg-paper rounded-4">
              <Button
                className="uppercase h-32 w-104 text-white text-10 font-400 ml-16"
                variant="contained"
                color="primary"
              >
                Browse
              </Button>
              <Typography className="text-13 font-300 px-8 text-secondaryLight">
                Drag and drop file here or browse
              </Typography>
            </div>
          )}
        </DragAndDrop>
      </div>
      {hasFile && !isCustomComponent && (
        <div
          tabIndex={0}
          role="button"
          className="flex items-center mt-12"
          onClick={() => handleFileClick(value)}
        >
          <AttachFileIcon className="text-20 mr-4 -ml-4" />
          <Typography className="text13 font-600 hover:underline cursor-pointer">{value?.file?.name}</Typography>
        </div>
      )}
    </div>
  );
};

const useStyles: any = makeStyles(() => ({
  imageContainer: {
    '&:hover': {
      '& $imageUploadButton': {
        zIndex: 1,
      },
    },
  },
  imageUploadButton: {
    zIndex: -1,
  },
}));

export default FileUpload;
