import clsx from 'clsx';
import Blocks from 'editorjs-blocks-react-renderer';

import _ from '@lodash';
import { makeStyles } from '@mui/styles';

import { BLOCK_CONFIGS, RENDERERS } from './config/block';
import './styles/viewer.css';

const useStyles = makeStyles((theme: any) => ({
  container: {
    fontFamily: theme.typography?.fontFamily,
    fontSize: '12pt',
    color: '#343741',
  },
}));

type Props = {
  content?: any;
  className?: string;
};

function EditorViewer({ content, className }: Props) {
  const classes = useStyles();
  if (_.isEmpty(content)) return null;

  return (
    <div className={clsx('editor-viewer', classes.container, className)}>
      <Blocks
        data={content}
        config={BLOCK_CONFIGS}
        renderers={RENDERERS}
      />
    </div>
  );
}

export default EditorViewer;
