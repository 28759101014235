import { authRoles } from 'app/auth';

import NotFound from './404';

const EmptyPagesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      component: NotFound,
    },
  ],
};

export default EmptyPagesConfig;
