import clsx from 'clsx';

import Typography from '@mui/material/Typography';

type Props = {
  item: any;
  highlight?: boolean;
};

function TagItem({ item, highlight }: Props) {
  return (
    <div
      className={clsx(
        'inline-flex items-center justify-center min-w-64 h-24 rounded-16 shadow px-8 mr-8 mb-8 max-w-136',
        highlight ? 'bg-warning' : 'bg-gray-200',
      )}
    >
      <Typography
        className="text-11 font-600 truncate"
        title={item.tagName}
      >
        {item.tagName}
      </Typography>
    </div>
  );
}

export default TagItem;
