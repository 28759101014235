import ErrorMessage from 'app/components/cores/form/error-message';
import { useEffect, useRef } from 'react';
import { Control, useController } from 'react-hook-form';

import EditorJs from './editor-js';

type Props = {
  placeholder?: string;
  name: string;
  control: Control;
  rules?: any;
  readOnly?: boolean;
  forceUpdate?: boolean;
};

function ControlledEditorJs({ placeholder, name, control, rules, readOnly, forceUpdate }: Props) {
  const editorRef = useRef<any>(null);
  const canRender = useRef(true);

  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  // just render one time then editor will manage it self
  useEffect(() => {
    if (!forceUpdate || !value || !canRender.current || !editorRef.current?.render) return;
    setTimeout(() => {
      editorRef.current?.render(value);
      canRender.current = false;
    }, 750);
  }, [value, forceUpdate]);

  return (
    <div>
      <EditorJs
        ref={editorRef}
        placeholder={placeholder}
        readOnly={readOnly}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default ControlledEditorJs;
