/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import { Paths } from 'constants/index';
import { TTag } from 'models';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { getFilterFromURL } from 'utils/url';

type Props = {
  tags: TTag[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  onFetchData: (params: any) => void;
};

function TagList({ tags, pageCount, totalDataCount, isLoading, searchKey, onFetchData }: Props) {
  const history = useHistory();
  const location = useLocation();

  const [filters, setFilters] = React.useState(getFilterFromURL(location?.search));

  const handlePreviewClick = (tagId: number) => {
    history.push(`${Paths.tag}/${tagId}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        width: '80%',
        Cell: ({ row }: { row: Row<TTag> }) => {
          return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onClick={() => handlePreviewClick(row.original.id)}
              className="font-600 cursor-pointer"
            >
              {row.original?.name}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        sortable: true,
        width: '100%',
        Filter: <div />,
        className: 'capitalize',
      },
    ],
    // eslint-disable-next-line
    [location],
  );

  return (
    <div className="flex flex-col w-full">
      <IndexTable
        columns={columns as Column<any>[]}
        data={tags}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}

export default TagList;
