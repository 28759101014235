import React, { useCallback, useEffect, useRef, useState } from 'react';

import _ from '@lodash';
import Input from '@mui/material/Input';
import { useTheme } from '@mui/material/styles';

type Props = {
  column: { [key: string]: any };
};

function TextFilter({ column: { filterValue, setFilter } }: Props) {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');

  const updateFilter = (text: string) => {
    return setFilter(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(_.debounce(updateFilter, 500), [searchText]);

  const firstMountRef = useRef(true);
  useEffect(() => {
    if (firstMountRef.current) {
      setSearchText(filterValue);
    } else {
      firstMountRef.current = false;
    }
    // eslint-disable-next-line
  }, [filterValue]);

  useEffect(() => {
    delayedQuery(searchText);

    return delayedQuery.cancel;
  }, [searchText, delayedQuery]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = event?.target?.value || '_all';
    setSearchText(text);
  };

  return (
    <Input
      style={{ background: theme.palette.background.paper }}
      className="px-8 w-full rounded-4 h-32"
      value={!searchText || searchText === '_all' ? '' : searchText}
      onChange={handleInputChange}
      placeholder="Search"
      disableUnderline={true}
    />
  );
}

export default TextFilter;
