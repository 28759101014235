// import queryClient from 'app/configs/query-client';
import { TArticle } from 'models';
import { useQuery } from 'react-query';
import api from 'utils/api';

export const employeeKeys = {
  all: ['employee'] as const,
  lists: () => [...employeeKeys.all, 'list'] as const,
  list: (filters: any) => [...employeeKeys.lists(), { filters }] as const,
  details: () => [...employeeKeys.all, 'detail'] as const,
  detail: (id: number) => [...employeeKeys.details(), id] as const,

  allArticles: ['employeeArticles'] as const,
  articleLists: () => [...employeeKeys.allArticles, 'list'] as const,
  articleList: (filters: any) => [...employeeKeys.articleLists(), { filters }] as const,
  articleDetails: () => [...employeeKeys.allArticles, 'detail'] as const,
  articleDetail: (slug: string) => [...employeeKeys.articleDetails(), slug] as const,
};

async function fetchEmployeeArticles(filters: any) {
  const res = await api.get('/api/employee/articles', { params: filters });
  return res.data;
}

export function useFetchArticles(filters: any) {
  return useQuery([...employeeKeys.articleList(filters)], () => fetchEmployeeArticles(filters), { cacheTime: 0 });
}

async function getEmployeeArticle(articleSlug: string) {
  const res = await api.get(`/api/employee/articles/${articleSlug}`);
  return res?.data;
}

export function useArticle(articleSlug: string, options?: any) {
  return useQuery<TArticle>([...employeeKeys.articleDetail(articleSlug)], () => getEmployeeArticle(articleSlug), {
    enabled: !!articleSlug,
    ...options,
  });
}

async function fetchEmployeeLocations(filters: any) {
  const res = await api.get('/api/employee/locations', { params: filters });
  return res.data;
}

export function useFetchEmployeeLocations(filters: any) {
  return useQuery([...employeeKeys.list(filters)], () => fetchEmployeeLocations(filters));
}
