import axios, { AxiosError, AxiosResponse } from 'axios';

import * as Sentry from '@sentry/react';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE || '',
  headers: {
    'Content-type': 'application/json',
  },
  responseType: 'json',
});

export const baseUrl: string | undefined = process.env.REACT_APP_API_BASE;

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

export default axiosInstance;
