import clsx from 'clsx';

import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  visible?: boolean;
  opacity?: boolean;
};

function Loading({ visible, opacity }: Props) {
  if (!visible) return null;
  return (
    <div
      className={clsx(
        'flex items-center justify-center absolute inset-0 z-9999 w-full bg-white',
        opacity && 'bg-opacity-50',
      )}
    >
      <CircularProgress color="primary" />
    </div>
  );
}

export default Loading;
