import Header from 'app/components/Layout/headers/blank-header-v2';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import TagForm from 'app/components/tags/form';
import { useCreateTag } from 'app/hooks/react-query/tags';
import useShowMessage from 'app/hooks/use-show-message';
import { Paths } from 'constants/index';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mui/material/Icon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
  })
  .required();

function New() {
  const [tabValue] = useState(0);
  const isLoading = false;
  const history = useHistory();
  const { mutateAsync: createTag } = useCreateTag();
  const form = useForm({ resolver: yupResolver(schema) });
  const { showError, showSuccess } = useShowMessage();

  const handleBackClick = () => {
    history.goBack();
  };

  const handleCancelButton = () => {
    handleBackClick();
  };

  const handleAddTag = (value: any) => {
    createTag(value)
      .then(() => {
        showSuccess('Tag has been successfully created');
        history.push(Paths.tag);
      })
      .catch(res => {
        res?.errors.forEach((error: any) => {
          const message = `${error?.field} ${error?.message?.[0]}`;
          form.setError(error?.field, { type: 'custom', message });
        });
        showError('Could not create a new tag');
      });
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              className="flex items-center sm:mb-12 uppercase text-secondaryMain"
              role="button"
              onClick={handleBackClick}
              color="inherit"
              variant="button"
            >
              <div className="flex flex-row">
                <Icon
                  role="button"
                  className="text-18 mt-4"
                >
                  arrow_back_ios
                </Icon>
                <div className="flex flex-col">
                  <Typography className="text-18 font-600 ml-16">Add New Tag</Typography>
                </div>
              </div>
            </Typography>
          }
          rightHeader={
            <div className="flex flex-row">
              {isLoading ? (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap normal-case mx-8"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="whitespace-no-wrap normal-case mx-8"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap"
                    onClick={handleCancelButton}
                    btnTitle="Cancel"
                  />
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant="contained"
                    onClick={form.handleSubmit(handleAddTag)}
                    btnTitle="Save"
                  />
                </div>
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full h-48 relative' }}
        >
          <Tab
            className="h-48 normal-case font-400"
            label="Tag Settings"
          />
        </Tabs>
      }
      content={
        <div className="p-16 sm:p-24 w-full h-full bg-paper">
          <FormProvider {...form}>
            <TagForm
              tabIndex={tabValue}
              type="new"
            />
          </FormProvider>
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default New;
