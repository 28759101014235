import { QueryClient } from 'react-query';

const ONE_SECOND = 1000;

function useErrorHandler() {}

function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: ONE_SECOND * 10, // 10 seconds
        cacheTime: ONE_SECOND * 10, // 10 seconds
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onError: useErrorHandler,
      },
    },
  });
}

const queryClient = generateQueryClient();

export default queryClient;
