/* eslint-disable */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function News(props: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 250.128 250.128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M229.415,73.41c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5c3.15,0,5.713,2.557,5.713,5.699v97.187
		c0,8.038-6.539,14.577-14.577,14.577s-14.577-6.539-14.577-14.577V49.955c0-11.414-9.292-20.7-20.714-20.7H20.698
		C9.285,29.255,0,38.541,0,49.955V200.16c0,11.161,8.874,20.265,19.93,20.674c0.253,0.026,0.509,0.039,0.769,0.039h177.775h22.077
		c16.309,0,29.577-13.268,29.577-29.577V94.109C250.128,82.696,240.836,73.41,229.415,73.41z M15,200.16V49.955
		c0-3.143,2.556-5.7,5.698-5.7H185.26c3.15,0,5.714,2.557,5.714,5.7v141.341c0,5.298,1.407,10.271,3.857,14.577H20.698
		C17.556,205.873,15,203.31,15,200.16z"
        fill="currentColor"
      />
      <path
        d="M156.235,92.182h-46.683c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h46.683c4.142,0,7.5-3.358,7.5-7.5
		S160.377,92.182,156.235,92.182z"
        fill="currentColor"
      />
      <path
        d="M156.235,129.518H51.2c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h105.035c4.142,0,7.5-3.358,7.5-7.5
		C163.735,132.876,160.377,129.518,156.235,129.518z"
        fill="currentColor"
      />
      <path
        d="M156.235,166.854H51.2c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h105.035c4.142,0,7.5-3.358,7.5-7.5
		C163.735,170.212,160.377,166.854,156.235,166.854z"
        fill="currentColor"
      />
      <path
        d="M49.738,107.182h29.907c4.142,0,7.5-3.358,7.5-7.5V69.775c0-4.142-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v22.407
		H49.738c-4.142,0-7.5,3.358-7.5,7.5S45.596,107.182,49.738,107.182z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(News);
