import clsx from 'clsx';
import React from 'react';

import { Button, CircularProgress, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: any) => {
  return {
    button: {
      width: 88,
      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.5),
        color: '#fff',
      },
      '&:disabled': {
        border: `1px solid ${theme.palette.secondary.light}`,
        color: theme.palette.secondary.light,
        '&:hover': {
          cursor: 'not-allowed',
        },
      },
    },
  };
});

type Props = {
  isFetchingData?: boolean;
  disabled?: boolean;
  btnTitle?: string;
  className?: string;
  color?: any;
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => void;
  testName?: string;
};

const HeadButtonRight = ({
  isFetchingData,
  disabled,
  btnTitle,
  className,
  variant,
  color,
  testName,
  onClick,
}: Props) => {
  const classes = useStyles();

  function handleOnClick(event: React.MouseEvent) {
    event.preventDefault();
    onClick?.();
  }

  return (
    <Button
      className={clsx(classes.button, 'h-32 whitespace-no-wrap', className, isFetchingData && 'pointer-events-none')}
      variant={variant || 'outlined'}
      color={color || 'primary'}
      disabled={disabled || false}
      onClick={handleOnClick}
      data-testid={testName || 'btnRightTest'}
    >
      {isFetchingData ? (
        <CircularProgress
          color="primary"
          size={20}
        />
      ) : (
        <span className="sm:flex uppercase text-11 font-400">{btnTitle}</span>
      )}
    </Button>
  );
};

export default React.memo(HeadButtonRight);
