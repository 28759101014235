import clsx from 'clsx';
import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  },
}));

type Props = {
  children: React.ReactNode;
};

function HeaderContainer({ children }: Props) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        'bg-white border-b-1 border-gray-200 flex items-center py-8 pl-16 sticky top-0 z-99 min-h-64 overflow-y-scroll overflow-x-hidden',
        classes.root,
      )}
    >
      {children}
    </div>
  );
}

export default HeaderContainer;
