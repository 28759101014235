import { authRoles } from 'app/auth';
import { Paths } from 'constants/index';

import Edit from './edit';
import TagIndex from './index';
import New from './new';

const TagsPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.editor,
  routes: [
    {
      path: `${Paths.tag}`,
      exact: true,
      component: TagIndex,
    },
    {
      path: `${Paths.tag}/new`,
      exact: true,
      component: New,
    },
    {
      path: `${Paths.tag}/:tagId`,
      exact: true,
      component: Edit,
    },
  ],
};

export default TagsPagesConfig;
