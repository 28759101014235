import Embed from '@editorjs/embed';

class CustomEmbedTool extends Embed {
  onPaste(event: any) {
    const { key: service, data: url } = event.detail;
    const newUrl = url?.trim();

    const { regex, embedUrl, width, height, id = (ids: any) => ids.shift() } = Embed.services[service];
    const result = regex.exec(newUrl).slice(1);
    const embed = embedUrl.replace(/<%= remote_id %>/g, id(result));

    this.data = {
      service,
      source: newUrl,
      embed,
      width,
      height,
    };
  }
}

export default CustomEmbedTool;
