import { RenderFn } from 'editorjs-blocks-react-renderer';

const Raw: RenderFn<{}> = ({ data, className = '' }: any) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: data?.html || '' }}
    />
  );
};

export default Raw;
