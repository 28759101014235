import clsx from 'clsx';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  saveButton: {
    background: theme.palette.primary.main,
    borderRadius: 4,
    border: 0,
    color: 'white',
    width: 88,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cancelButton: {
    background: theme.palette.background.paper,
    color: theme.palette.secondary.light,
    borderRadius: 4,
    border: 0,
    width: 88,
    height: 32,
  },
  iconClose: {
    position: 'absolute',
    right: 1,
    top: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dialogSize: {
    minWidth: 388,
    minHeight: 150,
    borderLeft: '4px solid',
    borderRadius: 4,
    background: theme.palette.background.default,
  },
}));

type Props = {
  title: string;
  message?: string;
  confirmButtonLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
  statusVariant?: string;
};
const ConfirmDialog = ({ title, message, confirmButtonLabel, statusVariant, onClose, onConfirm }: Props) => {
  const classes = useStyles();
  function getColor() {
    switch (statusVariant) {
      case 'success':
        return 'border-green';
      case 'warning':
        return 'border-yellow';
      default:
        return 'border-red';
    }
  }
  return (
    <div className={clsx(classes.dialogSize, getColor())}>
      <DialogTitle
        id="alert-dialog-title"
        className="h-40 mt-8"
      >
        <Typography className="font-600">{title}</Typography>
        <IconButton
          aria-label="close"
          className={classes.iconClose}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="text-secondaryMain"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          autoFocus
          className={classes.cancelButton}
        >
          <Typography className="text-11">Cancel</Typography>
        </Button>
        <Button
          classes={{ root: classes.saveButton }}
          onClick={onConfirm}
        >
          <Typography className="text-11">{confirmButtonLabel || 'SAVE'}</Typography>
        </Button>
      </DialogActions>
    </div>
  );
};

export default ConfirmDialog;
