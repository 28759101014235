import { TArticleAttribute, TArticleAttributeKey, TMedia } from 'models';

export const formatInputMedia = (media?: TMedia) => {
  if (!media?.id) return {};
  return {
    id: media?.id,
    file: media?.objectFiles?.[0],
  };
};

export const formatInputAttributes = (attributes: TArticleAttribute[], fields: string[]) => {
  return (
    attributes?.map((item: TArticleAttribute) => ({
      recordId: item.id,
      id: item?.[fields[0] as TArticleAttributeKey],
      name: item?.[fields[1] as TArticleAttributeKey],
    })) || []
  );
};

export const formatOutputAttributes = (attributes: TArticleAttribute[], field: string) => {
  return (
    attributes?.map((item: any) => ({
      _destroy: item?._destroy,
      id: item?.recordId,
      [field]: item.id,
    })) || []
  );
};
