import { authRoles } from 'app/auth';

import Article from './article';

const PublicPagesConfig = {
  settings: {
    layout: {
      config: {
        noLayout: true,
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/public/news/:slug',
      exact: true,
      component: Article,
    },
  ],
};

export default PublicPagesConfig;
