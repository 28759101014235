import { useEffect, useState } from 'react';

import _ from '@lodash';

function useScrollBottom() {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const contentWrapper = document.querySelector('.contentWrapper') as any;
    if (!contentWrapper) return;

    const handleScroll = _.throttle((event: any) => {
      const scrollHeight = event.target?.scrollHeight || 0;
      const offsetHeight = event.target?.offsetHeight || 0;
      const scrollTop = event.target?.scrollTop || 0;
      const isBottom = scrollHeight - offsetHeight < scrollTop + 300;

      setIsBottom(isBottom);
    }, 100);

    contentWrapper?.addEventListener('scroll', handleScroll);

    return () => {
      contentWrapper?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isBottom;
}

export default useScrollBottom;
