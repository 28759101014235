import { adminLoginNavigationConfig } from 'app/configs/navigationConfig';
import { Paths } from 'constants/index';
import { updateServices } from 'domains/service.domain';
import { TService } from 'models';
import { isEditor } from 'utils/role';

import _ from '@lodash';

export function getBadgeNumberStyle(count?: number) {
  if (!count) return '';
  if (count < 100) return 'text-13'; // 0..99
  if (count < 1000) return 'text-9'; // 100..999
  if (count < 10000) return 'text-7'; // 1000..9999
  return 'text-6'; // 10000..∞
}

export function canShowNavigationSwitch(userRoles: string[] = []) {
  return !!isEditor(userRoles);
}

export function getSSOProfileUrl(services?: TService[]) {
  if (!services) return '';
  const service = _.find(services, { kind: 'sso' });
  return service ? `${service.url}/profile` : '';
}

export function getLoginMenu(services?: TService[]) {
  const ssoProfileUrl = getSSOProfileUrl(services);

  if (!ssoProfileUrl) return adminLoginNavigationConfig;

  return _.map(adminLoginNavigationConfig, (item: any) => {
    if (item.id === 'login') {
      const profileNavigation = _.find(item.children, { id: 'profile' });
      profileNavigation.url = ssoProfileUrl;
    }
    return item;
  });
}

export function generateNavItems(services: TService[]) {
  const nextServices = _.cloneDeep(services) || [];

  return _.chain(nextServices)
    .orderBy('order')
    .map((service: TService) => ({
      ...service,
      url: service.kind === 'hris_my_roster' ? `${service.url}${Paths.myShift}` : service.url,
    }))
    .value();
}

export function getLoginNavigationConfig(menu: any, services: TService[]) {
  const newMenu = _.cloneDeep(menu);
  if (_.isEmpty(services)) return newMenu;
  const navItems = updateServices(services);

  newMenu?.forEach((item: any) => {
    if (item.id === 'login') {
      const loginItems = _.cloneDeep(item.children);
      const formattedMenu = formatServiceLoginMenu(navItems);
      item.children = [...formattedMenu, ...loginItems];
    }
  });

  return newMenu;
}

export function formatServiceLoginMenu(navItems: any) {
  return navItems.map((obj: any) => {
    const type = 'item';
    const autoActive = obj.kind === 'news';
    const title = obj.name;

    const item = {
      id: obj.kind,
      title,
      translate: title,
      url: obj.url,
      type,
      isExternalUrl: true,
      exact: true,
      autoActive,
      isService: true,
    };
    return item;
  });
}

export function getBaseUrl(services: TService[]) {
  const ssoService = _.find(services, { kind: 'sso' });
  return ssoService?.url || '/';
}
