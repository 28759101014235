import queryClient from 'app/configs/query-client';
import { TArticle, TResponsePagination } from 'models';
import { useMutation, useQuery } from 'react-query';
import api from 'utils/api';

type TArticleResponse = TResponsePagination<TArticle>;

export const articleKeys = {
  all: ['articles'] as const,
  lists: () => [...articleKeys.all, 'list'] as const,
  list: (filters: any) => [...articleKeys.lists(), { filters }] as const,
  details: () => [...articleKeys.all, 'detail'] as const,
  detail: (id: number) => [...articleKeys.details(), id] as const,
};

async function fetchArticles(filters: any) {
  const res = await api.get('/api/articles', { params: filters });
  return res.data;
}

export function useFetchArticles(filters: any) {
  return useQuery<TArticleResponse>([...articleKeys.list(filters)], () => fetchArticles(filters), {
    refetchOnMount: true,
  });
}

async function getArticle(articleId: number) {
  const res = await api.get(`/api/articles/${articleId}`);
  return res?.data;
}

export function useArticle(articleId: number) {
  return useQuery<TArticle>([...articleKeys.detail(articleId)], () => getArticle(articleId), { enabled: !!articleId });
}

async function createArticle(data: any) {
  try {
    const res = await api.post('/api/articles', { article: data });
    return res.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
}

export function useCreateArticle() {
  return useMutation(createArticle, { onSuccess: () => queryClient.invalidateQueries(articleKeys.all) });
}

async function updateArticle(articleId: number, data: any) {
  try {
    const res = await api.patch(`/api/articles/${articleId}`, { article: data });
    return res.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
}

export function useUpdateArticle(articleId: number) {
  return useMutation(data => updateArticle(articleId, data), {
    onSuccess: () => queryClient.invalidateQueries(articleKeys.all),
  });
}
