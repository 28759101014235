import { useQuery } from 'react-query';
import api from 'utils/api';

export const locationKeys = {
  all: ['locations'] as const,
  lists: () => [...locationKeys.all, 'list'] as const,
  list: (filters: any) => [...locationKeys.lists(), { filters }] as const,
  details: () => [...locationKeys.all, 'detail'] as const,
  detail: (id: number) => [...locationKeys.details(), id] as const,
};

async function fetchLocations(filters: any) {
  const res = await api.get('/api/locations', { params: filters });
  return res.data;
}

export function useFetchLocations(filters: any) {
  return useQuery([...locationKeys.list(filters)], () => fetchLocations(filters));
}
