export const Paths = {
  root: '/',
  ssoLogin: '/sso_login',
  login: '/login',
  logout: '/sso_logout',
  article: '/articles',
  tag: '/tags',
  news: '/news',
  myShift: '/my-shifts',
  ess: '/ess',
  myRoster: '/my-roster',
};
