import { Search } from 'history';
import queryString from 'query-string';

/**
 * Generate url with pagination's page index param
 * @param {Location} currentLocation
 * @param {number} newPage
 * @return {Location}
 */
export function generatePaginationURLWithPage(currentLocation: Location, newPage: number): Location {
  if (newPage < 1) {
    return currentLocation;
  }
  const searchParams = queryString.parse(currentLocation.search);
  return {
    ...currentLocation,
    search: queryString.stringify({ ...searchParams, page: newPage }),
  };
}

/**
 * Generate url with filter data
 * @param {currentSearch} currentSearch
 * @param {object} queryObject
 * @return {Search}
 */
export function generateURLWithFilter(currentSearch: Search, queryObject: object): Search {
  const searchParams = queryString.parse(currentSearch);
  return queryString.stringify({ ...searchParams, ...queryObject }, { skipEmptyString: true });
}

/**
 * Generate url with pagination's page size param
 * @param {currentSearch} currentSearch
 * @param {number} newPage
 * @return {Location}
 */
export function generateURLWithPerPage(currentSearch: Search, newSize: number): Search {
  if (newSize < 0) {
    return currentSearch;
  }
  const searchParams = queryString.parse(currentSearch);
  return queryString.stringify({ ...searchParams, per_page: newSize, page: 1 });
}

/**
 * Get filter from url
 * @param {string} searchText
 * @return {Object[]}
 */
export function getFilterFromURL(searchText: string): {}[] {
  if (!searchText) return [];
  const urlQuery = queryString.parse(searchText);
  const result: any = [];

  Object.keys(urlQuery).forEach(field => {
    const value = urlQuery[field];
    let id = '';

    if (field === 'status' || field === 'role') {
      id = field;
    } else if (/^q\[[a-zA-Z_]+\]$/.test(field)) {
      const pattern1 = /q\[|\]/g;
      const pattern2 = /_eq|_in|_lt|_lteq|_gt|_gteq|_cont$/;
      id = field.replace(pattern1, '').replace(pattern2, '');
    }

    if (id && value) {
      result.push({ id, value });
    }
  });

  return result;
}
