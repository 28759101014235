function hasRole(userRoles: string[], roleName: string): boolean {
  if (!userRoles || !Array.isArray(userRoles) || userRoles.length === 0) return false;
  return userRoles.some((role: string) => role === roleName);
}

export function isEditor(userRoles: string[]): boolean {
  return hasRole(userRoles, 'editor');
}

export function isEmployee(userRoles: string[]): boolean {
  return hasRole(userRoles, 'employee');
}

function hasRoleFromIds(accessRoles?: string[], roleId?: string) {
  if (!accessRoles?.length || !roleId) return false;
  return !!accessRoles?.includes(roleId);
}

export function isHrisAdmin(accessRoles?: string[]) {
  return hasRoleFromIds(accessRoles, '1');
}

export function isHrisManager(accessRoles?: string[]) {
  return hasRoleFromIds(accessRoles, '3');
}

export function isHrisEmployee(accessRoles?: string[]) {
  return hasRoleFromIds(accessRoles, '2');
}

export function canAccessDashboard(accessRoles?: string[]) {
  return !!accessRoles?.length;
}
