import ErrorMessage from 'app/components/cores/form/error-message';
import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';

import Input from './input';

type Props = {
  name: string;
  control: Control;
  rules?: any;
  showErrorMessage?: boolean;
} & TextFieldProps;

function ControlledInput({ name, control, rules, showErrorMessage = true, ...textFieldProps }: Props) {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = !!error?.message;

  const handleChange = (event: any) => {
    const { value } = event.target;
    onChange?.(value);
  };

  return (
    <div>
      <Input
        {...textFieldProps}
        ref={ref}
        InputProps={{
          ...textFieldProps.InputProps,
          classes: {
            ...textFieldProps.InputProps?.classes,
            input: clsx(textFieldProps.InputProps?.classes?.input, hasError && 'text-errorMain'),
          },
        }}
        value={value || ''}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {showErrorMessage && <ErrorMessage message={error?.message} />}
    </div>
  );
}

export default ControlledInput;
