import MenuTablet from './MenuTablet';

type Props = {
  items?: any;
};

function TopBarTablet({ items }: Props) {
  return (
    <>
      <div className="h-64 bg-white items-center px-24 py-6 flex w-full">
        <MenuTablet items={items} />
      </div>
    </>
  );
}

export default TopBarTablet;
