import { authRoles } from 'app/auth';
import { Paths } from 'constants/paths';

import Detail from './detail';
import Index from './index';

const NewsPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: Paths.news,
      exact: true,
      component: Index,
    },
    {
      path: `${Paths.news}/:articleSlug`,
      exact: true,
      component: Detail,
    },
  ],
};

export default NewsPagesConfig;
