import clsx from 'clsx';
import { RenderFn } from 'editorjs-blocks-react-renderer';

import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles(() => ({
  button: {
    border: '0 !important',
    backgroundColor: '#00A0D4 !important',
    '&:hover': {
      backgroundColor: '#80BFE4 !important',
    },
  },
}));

const Button: RenderFn<{}> = ({ data, className = '' }: any) => {
  const classes = useStyles();
  const link = data?.link || window.location.href;
  const text = data?.text || 'Button';
  const alignment = data?.alignment || 'left';

  const alignmentClassName = (alignment: string) => {
    const alignments: any = {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    };
    return alignments[alignment] || 'text-left';
  };

  return (
    <div className={clsx(className, alignmentClassName(alignment))}>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={clsx(
          'text-white font-400 no-underline inline-flex items-center justify-center h-36 px-16 rounded-4 duration-200 align-middle',
          classes.button,
        )}
      >
        {text}
      </a>
    </div>
  );
};

export default Button;
