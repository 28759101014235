/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import { TagsCell } from 'app/components/cores/index-table/cells';
import { Paths } from 'constants/index';
import { TArticle } from 'models';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { getFilterFromURL } from 'utils/url';

type Props = {
  articles: TArticle[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  onFetchData: (params: any) => void;
};

function ArticleList({ articles, pageCount, totalDataCount, isLoading, onFetchData }: Props) {
  const history = useHistory();
  const location = useLocation();

  const [filters, setFilters] = React.useState(getFilterFromURL(location?.search));

  const handlePreviewClick = (articleId: number) => {
    history.push(`${Paths.article}/${articleId}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        sortable: true,
        width: '50%',
        Cell: ({ row }: { row: Row<TArticle> }) => {
          return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onClick={() => handlePreviewClick(row.original.id)}
              className="font-600 cursor-pointer"
            >
              {row.original?.title}
            </div>
          );
        },
      },
      {
        Header: 'Tag',
        accessor: 'tags',
        width: '20%',
        className: 'text-13 font-400',
        Filter: <div />,
        Cell: ({ row }: { row: Row<any> }) => <TagsCell row={row} />,
      },
      {
        Header: 'Author',
        accessor: 'author',
        sortable: true,
        width: '20%',
        Filter: <div />,
        Cell: ({ cell }: { cell: Cell<TArticle> }) => {
          const author = `${cell.value.firstName} ${cell.value.lastName}`;
          return <div className="font-400 cursor-pointer">{author}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        sortable: true,
        width: '100%',
        Filter: <div />,
        className: 'capitalize',
      },
    ],
    // eslint-disable-next-line
    [location],
  );

  return (
    <div className="flex flex-col w-full">
      <IndexTable
        columns={columns as Column<any>[]}
        data={articles}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        location={location}
        history={history}
        onFetchData={onFetchData}
        searchText=""
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}

export default ArticleList;
