import Header from 'app/components/Layout/headers/internal-search-header';
import ArticleList from 'app/components/articles/list';
import HeadButtonRight from 'app/components/cores/header-button-right';
import { useFetchArticles } from 'app/hooks/react-query/articles';
import { Paths } from 'constants/index';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BluefitPage from '@fuse/core/BluefitPage';

function ArticleIndex() {
  const history = useHistory();

  const [filter, setFilter] = useState<{ [key: string]: any }>({
    page: 1,
    per_page: 50,
  });
  const { data, isLoading } = useFetchArticles(filter);

  const articles = data?.records || [];
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const searchString = 'q[title_cont]';

  const handleSearch = (value: string) => {
    setFilter({ ...filter, page: 1, [searchString]: value });
  };

  const handleFetchData = (fetchParams: any) => {
    setFilter(fetchParams);
  };

  const handleAddArticle = () => {
    history.push(`${Paths.article}/new`);
  };

  return (
    <BluefitPage
      classes={{
        contentWrapper: 'p-0 pl-10',
        content: 'flex flex-col relative',
        leftSidebar: 'w-256 border-0',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          title="Article"
          searchText=""
          onSearch={(value: string) => {
            handleSearch(value);
          }}
          rightHeader={
            <div>
              <HeadButtonRight
                className="ml-4"
                variant="contained"
                btnTitle="Add"
                onClick={handleAddArticle}
              />
            </div>
          }
        />
      }
      content={
        <div className="flex absolute w-full h-full">
          <ArticleList
            articles={articles}
            onFetchData={handleFetchData}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isLoading}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default ArticleIndex;
