import { Paths } from 'constants/paths';

export const editorNavigationConfig = [
  {
    id: 'tags',
    title: 'Tags',
    translate: 'Tags',
    type: 'item',
    icon: 'local_offer_outlined',
    url: Paths.tag,
    exact: true,
  },
  {
    id: 'article',
    title: 'Articles',
    translate: 'Articles',
    type: 'item',
    icon: 'news',
    url: Paths.article,
    exact: true,
    isExternalIcon: true,
  },
];

export const userNavigationConfig = [];

export const loginNavigationConfig = [
  {
    id: 'login',
    title: 'Login',
    type: 'login',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'Profile',
        type: 'item',
        url: '/profile',
        isExternalUrl: true,
      },
      {
        id: 'logout',
        title: 'Logout',
        translate: 'Logout',
        type: 'item',
        url: '/users/sign_out',
      },
    ],
  },
];

export const adminLoginNavigationConfig = [
  {
    id: 'login',
    title: 'Login',
    type: 'login',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'Profile',
        type: 'item',
        url: '/profile',
        isExternalUrl: true,
      },
      {
        id: 'logout',
        title: 'Logout',
        translate: 'Logout',
        type: 'item',
        url: '/logout',
      },
    ],
  },
];
