import clsx from 'clsx';
import { RenderFn } from 'editorjs-blocks-react-renderer';

const Image: RenderFn<{}> = ({ data, className = '' }: any) => {
  return (
    <div
      className={clsx(
        className,
        data.stretched && 'editor-viewer__image--stretched',
        data.withBackground && 'p-2 bg-paper editor-viewer__image--withBackground',
        data.withBorder && 'border border-gray-300',
      )}
    >
      <img
        alt={data?.file?.name}
        src={data?.file?.url}
        className="w-full object-contain"
      />
      {data.caption && <figcaption>{data.caption}</figcaption>}
    </div>
  );
};

export default Image;
