import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
  searchText: string;
  onClear?: () => void;
  onSearch?: (searchText: string) => void;
};

function Search({ searchText, onClear, onSearch }: Props) {
  const [isShowInput, setShowInput] = useState(false);
  const searchRef = useRef<any>(null);
  const isLargeScreen = useMediaQuery('(min-width:960px)');

  useEffect(() => {
    if (!searchRef.current) return;
    searchRef.current.value = searchText || '';
  }, [searchText]);

  const handleClearSearch = () => {
    searchRef.current.value = '';
    onClear?.();
  };

  const handleSearch = (event: any) => {
    const { value } = event.target;
    onSearch?.(value);
  };

  const handleSearchClick = () => {
    setShowInput(true);
  };

  const handleCloseSearch = () => {
    setShowInput(false);
  };

  if (!isLargeScreen && !isShowInput) {
    return (
      <IconButton
        className="bg-gray-100"
        onClick={handleSearchClick}
      >
        <SearchIcon className="text-20" />
      </IconButton>
    );
  }

  return (
    <div className="w-full sm:w-320 md:w-360">
      <div
        className={clsx(
          'md:relative',
          !isLargeScreen && isShowInput && 'absolute inset-0 z-10 bg-white flex items-center px-24',
        )}
      >
        {!isLargeScreen && isShowInput && (
          <IconButton onClick={handleCloseSearch}>
            <ArrowBackIcon className="text-20" />
          </IconButton>
        )}
        <input
          ref={searchRef}
          placeholder="Search..."
          className="w-full h-44 bg-gray-200 rounded-24 outline-none pl-16 pr-48 font-sans"
          defaultValue={searchText}
          onChange={handleSearch}
        />
        <div className="w-40 h-full md:h-40 flex items-center justify-center absolute top-0 right-0 mr-32 md:mr-8">
          {searchText ? (
            <CloseIcon
              className="text-20 text-secondaryLight hover:text-secondaryMain cursor-pointer"
              onClick={handleClearSearch}
            />
          ) : (
            <SearchIcon className="text-20 text-secondaryLight" />
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;
