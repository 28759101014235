import _ from '@lodash';

const replaceIcon = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="16" height="16">
  <g>
    <path d="M51.2,25.6v25.6h102.4v102.4H51.2h0l0-102.4v0V25.6V0C22.9,0,0,22.9,0,51.2v102.4c0,28.3,22.9,51.2,51.2,51.2l102.4,0
      c28.3,0,51.2-22.9,51.2-51.2l0-102.4c0-28.3-22.9-51.2-51.2-51.2H51.2V25.6z" fill="#000"/>
    <path d="M358.4,332.8v25.6h102.4v102.4H358.4l0,0V358.4v0V332.8v-25.6c-28.3,0-51.2,22.9-51.2,51.2v102.4
      c0,28.3,22.9,51.2,51.2,51.2l102.4,0c28.3,0,51.2-22.9,51.2-51.2V358.4c0-28.3-22.9-51.2-51.2-51.2H358.4V332.8z" fill="#000"/>
    <path d="M512,230.4v-76.8c0-42.4-34.4-76.8-76.8-76.8H281.6c-10.4,0-19.7,6.2-23.7,15.8c-4,9.6-1.8,20.6,5.5,27.9l76.8,76.8
      c10,10,26.2,10,36.2,0c10-10,10-26.2,0-36.2L343.4,128l91.8,0c14.1,0,25.6,11.5,25.6,25.6v76.8c0,14.1,11.5,25.6,25.6,25.6
      C500.5,256,512,244.5,512,230.4L512,230.4z M340.3,7.5l-76.8,76.8c-10,10-10,26.2,0,36.2c10,10,26.2,10,36.2,0l76.8-76.8
      c10-10,10-26.2,0-36.2C366.5-2.5,350.3-2.5,340.3,7.5z" fill="#000"/>
    <path d="M0,281.6v76.8c0,42.4,34.4,76.8,76.8,76.8h153.6c10.4,0,19.7-6.2,23.7-15.8c4-9.6,1.8-20.6-5.5-27.9l-76.8-76.8
      c-10-10-26.2-10-36.2,0s-10,26.2,0,36.2l33.1,33.1l-91.8,0c-14.1,0-25.6-11.5-25.6-25.6v-76.8c0-14.1-11.5-25.6-25.6-25.6
      C11.5,256,0,267.5,0,281.6z M171.7,504.5l76.8-76.8c10-10,10-26.2,0-36.2c-10-10-26.2-10-36.2,0l-76.8,76.8c-10,10-10,26.2,0,36.2
      C145.5,514.5,161.7,514.5,171.7,504.5z" fill="#000"/>
  </g>
</svg>
`;

export const replaceImageAction = {
  name: 'replace_image',
  title: 'Replace image',
  icon: replaceIcon,
  action() {
    // open file browser
    const onSelectFile = _.get(this, 'ui.onSelectFile');
    onSelectFile?.();

    // detect image container content change
    // remove first image if has two images
    const imageContainer = _.get(this, 'ui.nodes.imageContainer');
    imageContainer?.addEventListener('DOMSubtreeModified', () => {
      const imageEls = imageContainer?.querySelectorAll('.image-tool__image-picture');

      if (imageEls?.length > 1) {
        imageEls?.[0].remove();
      }
    });

    return false;
  },
};
