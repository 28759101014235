import { ControlledSelect } from 'app/components/cores/select';
import { useFetchLocations } from 'app/hooks/react-query/locations';
import useSelect from 'app/hooks/use-select';
import _ from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function LocationSelect() {
  const { control } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const articleLocationCategoriesAttributes = useWatch({
    control,
    name: 'articleLocationCategoriesAttributes',
  });
  const locationCategoryIds = _.chain(articleLocationCategoriesAttributes)
    .filter(item => !item._destroy)
    .map('recordId')
    .value();

  const { data, isLoading } = useFetchLocations({
    page: pageIndex,
    per_page: pageSize,
    search: searchText,
    with_salt: false,
    'q[location_categories_id_in]': _.isEmpty(locationCategoryIds) ? undefined : locationCategoryIds,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  return (
    <ControlledSelect
      isMulti={true}
      menuPlacement="auto"
      placeholder="Locations"
      allOptionsPlaceholder={true}
      name="articleLocationsAttributes"
      control={control}
      options={options}
      getOptionValue={(option: any) => option.id}
      getOptionLabel={(option: any) => option.name}
      onInputChange={handleSearch}
      onMenuScrollToBottom={handleLoadMore}
    />
  );
}

export default LocationSelect;
