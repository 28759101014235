import ArticlesConfig from 'app/pages/articles/config';
import EmptyConfig from 'app/pages/empty/config';
import ErrorConfig from 'app/pages/error/config';
import LoginConfig from 'app/pages/login/loginConfig';
import LogoutConfig from 'app/pages/logout/LogoutConfig';
import MyNewsConfig from 'app/pages/news/config';
import PublicPagesConfig from 'app/pages/public/config';
import TagsConfig from 'app/pages/tags/config';
import { isDevelopment } from 'utils/env';

import FuseUtils from '@fuse/utils';

const routeConfigs = [
  PublicPagesConfig,
  MyNewsConfig,
  ArticlesConfig,
  TagsConfig,
  LogoutConfig,
  EmptyConfig,
  ErrorConfig,
];

if (isDevelopment) {
  routeConfigs.unshift(LoginConfig);
}

const routes = [...FuseUtils.generateRoutesFromConfigs(routeConfigs)];

export default routes;
