import clsx from 'clsx';
import { forwardRef } from 'react';

import { TextField, TextFieldProps } from '@mui/material';

const Input = forwardRef((props: TextFieldProps, ref: any) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      className={clsx('w-full', props.className)}
    />
  );
});

export default Input;
