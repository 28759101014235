import queryClient from 'app/configs/query-client';
import { TResponsePagination, TTag } from 'models';
import { useMutation, useQuery } from 'react-query';
import api from 'utils/api';

type TTagResponse = TResponsePagination<TTag>;

export const tagKeys = {
  all: ['tags'] as const,
  lists: () => [...tagKeys.all, 'list'] as const,
  list: (filters: any) => [...tagKeys.lists(), { filters }] as const,
  details: () => [...tagKeys.all, 'detail'] as const,
  detail: (id: number) => [...tagKeys.details(), id] as const,
};

async function fetchTags(filters: any) {
  const res = await api.get('/api/tags', { params: filters });
  return res.data;
}

export function useFetchTags(filters: any) {
  return useQuery<TTagResponse>([...tagKeys.list(filters)], () => fetchTags(filters), {
    refetchOnMount: true,
  });
}

async function fetchTag(tagId: number) {
  const res = await api.get(`/api/tags/${tagId}`);
  const data = res?.data;
  return data;
}

export function useTag(tagId: number) {
  return useQuery<TTag>([...tagKeys.detail(tagId)], () => fetchTag(tagId), { enabled: !!tagId });
}

async function createTag(data: any) {
  try {
    const res = await api.post('/api/tags', { tag: data });
    return res.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
}

export function useCreateTag() {
  return useMutation(createTag, { onSuccess: () => queryClient.invalidateQueries(tagKeys.all) });
}

async function updateTag(tagId: number, data: any) {
  try {
    const res = await api.patch(`/api/tags/${tagId}`, { tag: { name: data?.name, status: data?.status } });
    return res.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
}

export function useUpdateTag(tagId: number) {
  return useMutation(data => updateTag(tagId, data), { onSuccess: () => queryClient.invalidateQueries(tagKeys.all) });
}
