import { TEditorContent } from 'models';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js';

import _ from '@lodash';
import { makeStyles } from '@mui/styles';

import { EDITOR_JS_MAIN_TOOLS } from './config/tool';
import './styles/editor.css';

const ReactEditorJS = createReactEditorJS();

const useStyles = makeStyles((theme: any) => ({
  container: {
    fontFamily: theme.typography?.fontFamily,
    fontSize: '12pt',
    color: '#343741',
  },
}));

type Props = {
  placeholder?: string;
  defaultValue?: TEditorContent;
  value?: TEditorContent;
  readOnly?: boolean;
  onChange?: (data: TEditorContent) => void;
  onBlur?: () => void;
};

const EditorJs = forwardRef((props: Props, ref: any) => {
  const classes = useStyles();
  const { placeholder, defaultValue, value, readOnly, onChange, onBlur } = props;
  const editorCore = useRef<any>(null);

  const handleInitialize = useCallback((instance: any) => {
    editorCore.current = instance;
  }, []);

  const handleChange = async () => {
    const saveData = await editorCore.current?.save();
    onChange?.(saveData);
  };

  const generalFn = async (method: string, data?: TEditorContent) => {
    const saveData = await editorCore.current?.[method](data);
    return saveData;
  };

  const getValue = (value?: TEditorContent) => {
    return _.isEmpty(value) ? null : value;
  };

  useImperativeHandle(ref, () => ({
    destroy: () => generalFn('destroy'),
    clear: () => generalFn('clear'),
    save: () => generalFn('save'),
    render: (data: TEditorContent) => generalFn('render', data),
  }));

  return (
    <div className={classes.container}>
      <ReactEditorJS
        placeholder={placeholder}
        readOnly={readOnly}
        tools={EDITOR_JS_MAIN_TOOLS}
        defaultValue={defaultValue}
        value={getValue(value)}
        onInitialize={handleInitialize}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </div>
  );
});

export default EditorJs;
