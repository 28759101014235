import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tab, useTheme } from '@mui/material';

type Props = {
  label: string;
  isError?: boolean;
  [key: string]: any;
};

function ControlTab(props: Props) {
  const { label, isError, ...restProps } = props;
  const theme = useTheme();
  return (
    <Tab
      {...restProps}
      label={
        <div>
          {Boolean(isError) && (
            <ErrorOutlineIcon
              style={{ verticalAlign: 'middle', color: theme.palette.error.main, marginRight: 2 }}
              className="text-18"
              data-testid="icon"
            />
          )}{' '}
          {label}
        </div>
      }
    />
  );
}

export default ControlTab;
