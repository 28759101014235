import ErrorMessage from 'app/components/cores/form/error-message';
import { Control, useController } from 'react-hook-form';

import FileUpload, { FileUploadProps } from './file-upload';

type Props = {
  name: string;
  control: Control;
  rules?: any;
} & FileUploadProps;

function ControlledFileUpload({ name, control, rules, ...fileUploadProps }: Props) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <div>
      <FileUpload
        {...fileUploadProps}
        value={value}
        onChange={onChange}
        onRemove={() => onChange({})}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default ControlledFileUpload;
