import EditorViewer from 'app/components/cores/editor-js/editor-viewer';
import PublicContentContainer from 'app/components/cores/public/container';
import TagItem from 'app/components/tags/item';
import { useArticle } from 'app/hooks/react-query/employee';
import { TArticleTagAttribute } from 'models';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';

import Typography from '@mui/material/Typography';

type ParamsTypes = {
  slug: string;
};

function Article() {
  const routeParams = useParams<ParamsTypes>();
  const { slug } = routeParams;

  const [canGetContent, setCanGetContent] = useState(false);

  const { data, isLoading } = useArticle(slug, {
    enabled: canGetContent,
  });
  const image = data?.media?.objectFiles?.[0]?.url;
  const isLoadedContent = !!data?.id;

  return (
    <PublicContentContainer
      isLoading={isLoading}
      isLoadedContent={isLoadedContent}
      onSetToken={setCanGetContent}
    >
      {/* cover */}
      <div className="mb-24">
        {image && (
          <img
            alt="cover"
            src={image}
            className="w-full object-cover"
          />
        )}
      </div>

      {/* title */}
      <div className="mb-24">
        <Typography className="text-24 lg:text-40 font-700 uppercase">{data?.title}</Typography>
      </div>

      {/* tags */}
      <div className="mb-16">
        {data?.articleTagsAttributes?.map((tag: TArticleTagAttribute) => (
          <TagItem
            key={tag.id}
            item={tag}
          />
        ))}
      </div>

      {/* date */}
      <div className="mb-8">
        <Typography className="text-11 font-500 text-secondaryLight">
          {formatDate(data?.publishedAt, 'DD.MM.YYYY')}
        </Typography>
      </div>

      {/* content */}
      <div className="pb-24">
        <EditorViewer content={data?.content} />
      </div>
    </PublicContentContainer>
  );
}

export default Article;
