import ErrorMessage from 'app/components/cores/form/error-message';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { ActionMeta, Props as RSelectProps } from 'react-select';

import Select from './select';

type Props<T> = {
  name: string;
  control: Control;
  rules?: any;
  allOptionsPlaceholder?: boolean;
  overrideChangeFunction?: (
    currentValue: T[],
    previousValue: T[],
    actionPayload: ActionMeta<T>,
    onChange: (newValue: T[]) => void,
  ) => void;
} & RSelectProps;

function ControlledSelect<T extends unknown>({
  name,
  control,
  rules,
  overrideChangeFunction,
  ...selectProps
}: Props<T>) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const isMulti = selectProps?.isMulti;

  const presentValue = useMemo(() => {
    if (!isMulti) {
      return !value?.id ? null : value;
    }
    return !value?.length ? null : value;
  }, [isMulti, value]);

  const handleChange = (currentValue: any, actionPayload: ActionMeta<any>) => {
    if (!overrideChangeFunction) {
      onChange(currentValue);
    } else {
      overrideChangeFunction?.(currentValue, value, actionPayload, onChange);
    }
  };

  return (
    <div>
      <Select
        {...selectProps}
        value={presentValue}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default ControlledSelect;
