export function generateQueryObject(filters: { id: string; value: string }[]): { [key: string]: string } {
  // TODO: status is just a special case, this function should have ability to handle other cases
  return filters.reduce((queries: { [key: string]: string }, filter: { id: string; value: string }) => {
    let query = '';

    if (filter.id === 'status' || filter.id === 'role' || filter.id === 'q[status_eq]') {
      query = filter.id;
    } else if (filter.id === 'validated') {
      query = `q[${filter.id}_eq]`;
    } else {
      query = `q[${filter.id}_cont]`;
    }

    const value = filter.value === '_all' ? '' : filter.value;
    return { ...queries, [query]: value };
  }, {});
}
