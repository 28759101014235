import { ControlledSelect } from 'app/components/cores/select';
import { useFetchRoles } from 'app/hooks/react-query/roles';
import useSelect from 'app/hooks/use-select';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

function RoleSelect() {
  const { control } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data, isLoading } = useFetchRoles({
    page: pageIndex,
    per_page: pageSize,
    search: searchText,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  return (
    <ControlledSelect
      isMulti={true}
      menuPlacement="auto"
      placeholder="Roles"
      name="articleRolesAttributes"
      allOptionsPlaceholder={true}
      control={control}
      options={options}
      getOptionValue={(option: any) => option.id}
      getOptionLabel={(option: any) => option.name}
      onInputChange={handleSearch}
      onMenuScrollToBottom={handleLoadMore}
    />
  );
}

export default RoleSelect;
