import HeaderContainer from 'app/components/Layout/headers/header-container';
import Loading from 'app/components/cores/loading';
import NewsItem from 'app/components/news/item';
import LatestNews from 'app/components/news/latest-news';
import LocationDropdown from 'app/components/news/location-dropdown';
import ScrollTopButton from 'app/components/news/scroll-top-button';
import Search from 'app/components/news/search';
import Tags from 'app/components/news/tags';
import { useFetchArticles } from 'app/hooks/react-query/employee';
import useScrollBottom from 'app/hooks/use-scroll-bottom';
import useSelect from 'app/hooks/use-select';
import { Paths } from 'constants/paths';
import { TArticle, TLocation, TTag } from 'models';
import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import BluefitPage from '@fuse/core/BluefitPage';
import _ from '@lodash';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function Index() {
  const history = useHistory();
  const location = useLocation();

  const getDefaultSearchTitle = useCallback(() => {
    const search = queryString.parse(location.search);
    const title = search['q[title_cont]'] as string;
    return title || '';
  }, [location]);

  const getDefaultLocation = useCallback(() => {
    const search = queryString.parse(location.search);
    const locationId = search['q[location_id_eq]'] as string;
    const locationName = search['q[location_name_eq]'] as string;
    return getLocation(locationId, locationName);
  }, [location]);

  const getDefaultTag = useCallback(() => {
    const search = queryString.parse(location.search);
    const tagId = search['q[tags_id_eq]'] as string;
    const tagName = search['q[tags_name_eq]'] as string;

    if (!tagId || !tagName) return null;
    return {
      id: Number(tagId),
      name: tagName,
      status: '',
    };
  }, [location]);

  const [selectedLocation, setSelectedLocation] = useState<TLocation | null>(getDefaultLocation());
  const [selectedTag, setSelectedTag] = useState<TTag | null>(getDefaultTag());
  const [searchText, setSearchText] = useState(getDefaultSearchTitle());
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(20);
  const [loadingType, setLoadingType] = useState<'first_load' | 'load_more' | 'filter'>('first_load');
  const firstLoad = useRef(false);

  const canLoadMore = useScrollBottom();

  const { data, isLoading } = useFetchArticles({
    page: pageIndex,
    per_page: pageSize,
    'q[title_cont]': searchText || undefined,
    'q[tags_id_eq]': selectedTag?.id || undefined,
    'q[location_id_eq]': selectedLocation?.id || undefined,
  });

  const {
    options: articles,
    handleLoadMore,
    handleReset,
    handleSearch,
  } = useSelect({
    isReactSelect: false,
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const isFirstLoadType = loadingType === 'first_load';
  const isFilterType = loadingType === 'filter';
  const isLoadMoreType = loadingType === 'load_more';

  const isFirstTimeLoading = isLoading && isFirstLoadType;
  const isFiltering = isLoading && isFilterType;
  const isLoadingMore = isLoading && isLoadMoreType;
  const hasFilter = searchText || !!selectedTag?.id || !!selectedLocation?.id;

  // effect when url changed to set filter
  useEffect(() => {
    const defaultLocation = getDefaultLocation();
    const defaultTag = getDefaultTag();
    const defaultSearchTitle = getDefaultSearchTitle();

    handleReset();
    setLoadingType('filter');
    setSelectedLocation(defaultLocation);
    setSelectedTag(defaultTag);
    setSearchText(defaultSearchTitle);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!canLoadMore) return;
    handleLoadMore();
    setLoadingType('load_more');
    // eslint-disable-next-line
  }, [canLoadMore]);

  useEffect(() => {
    if (isLoading) return;
    firstLoad.current = true;
  }, [isLoading]);

  function getLocation(locationId?: number | string, locationName?: string) {
    if (!locationId || !locationName) {
      return {
        id: 0,
        name: 'All Locations',
      };
    }
    return {
      id: Number(locationId),
      name: locationName,
    };
  }

  const handleClickItem = (item: TArticle) => {
    history.push(`${Paths.news}/${item.slug || item.id}`);
  };

  const handleSelectTag = (tag?: TTag) => {
    const tagValue = !tag || tag?.id === selectedTag?.id ? null : tag;
    const tagId = !tagValue?.id ? undefined : tagValue?.id;
    const tagName = !tagValue?.id ? undefined : tagValue?.name;

    handleReset();
    setLoadingType('filter');
    setSelectedTag(tagValue);
    updateUrl({
      'q[tags_id_eq]': tagId,
      'q[tags_name_eq]': tagName,
    });
  };

  const handleSelectLocation = (location: TLocation) => {
    const locationId = !location?.id ? undefined : location?.id;
    const locationName = !location?.id ? undefined : location?.name;

    handleReset();
    setLoadingType('filter');
    setSelectedLocation(location);
    updateUrl({
      'q[location_id_eq]': locationId,
      'q[location_name_eq]': locationName,
    });
  };

  const handleSearchArticle = (value: string) => {
    handleReset();
    setLoadingType('filter');
    searchArticleDebounce(value);
  };

  const handleClearSearch = () => {
    const value = '';
    handleReset();
    setLoadingType('filter');
    updateUrl({ 'q[title_cont]': value || undefined });
  };

  const searchArticleDebounce = _.debounce((value: string) => {
    handleSearch(value, null);
    setSearchText(value);
    updateUrl({ 'q[title_cont]': value || undefined });
  }, 750);

  const updateUrl = (data: any) => {
    const searchJson = queryString.parse(location.search);
    const searchString = queryString.stringify({ ...searchJson, ...data });

    history.push({
      pathname: Paths.news,
      search: searchString,
    });

    if (_.has(data, 'q[title_cont]')) {
      setSearchText(data['q[title_cont]'] as string);
    }
  };

  const clearFilter = () => {
    if (!hasFilter) return;
    history.push(Paths.news);
  };

  return (
    <BluefitPage
      isNewLayout={true}
      classes={{
        contentWrapper: 'pt-24',
        content: 'flex flex-col sm:flex-row',
        leftSidebar: 'w-256 border-0',
        wrapper: isFirstTimeLoading ? 'bg-white' : 'bg-paper',
      }}
      header={
        <HeaderContainer>
          <div className="flex flex-1 items-center justify-between">
            <div
              className="flex items-center"
              role="button"
              tabIndex={0}
              onClick={clearFilter}
            >
              <div className="w-40 h-40 flex items-center justify-center mr-8 lg:mr-24">
                <HomeOutlinedIcon color="primary" />
              </div>
              <Typography className="text-15 font-600 uppercase">{hasFilter ? 'All News' : 'News'}</Typography>
            </div>

            <div className="flex items-center">
              <Search
                searchText={searchText}
                onClear={handleClearSearch}
                onSearch={handleSearchArticle}
              />
              <LocationDropdown
                className="w-188 sm:w-288 lg:w-320 pl-12 sm:pl-24 pr-24"
                location={selectedLocation}
                onChange={handleSelectLocation}
              />
            </div>
          </div>
        </HeaderContainer>
      }
      rightSidebar={
        <>
          <Tags
            selectedTag={selectedTag}
            onSelect={handleSelectTag}
          />
          <LatestNews className="mt-16 hidden sm:block" />
        </>
      }
      content={
        <>
          <div
            className="pr-24 sm:pr-0"
            id="articles"
          >
            <div className="flex flex-row flex-wrap">
              {articles?.map((item: any) => (
                <div
                  key={item.id}
                  className="inline-block pl-24 pb-24 w-full lg:w-1/2"
                >
                  <NewsItem
                    item={item}
                    selectedTag={selectedTag}
                    onClick={() => handleClickItem(item)}
                  />
                </div>
              ))}
              {!articles?.length && !isLoading && (
                <div className="pt-136 w-full text-center">
                  <Typography>No articles available</Typography>
                </div>
              )}
            </div>

            {isLoadingMore && (
              <div className="flex items-center justify-center py-24">
                <CircularProgress
                  color="primary"
                  size={24}
                />
              </div>
            )}
          </div>
          <Loading
            visible={isLoading && !isLoadMoreType}
            opacity={isFiltering}
          />

          <ScrollTopButton />
        </>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default Index;
