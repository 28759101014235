import { TTag } from 'models/index';
import { Cell, Row } from 'react-table';

import _ from '@lodash';
import Typography from '@mui/material/Typography';

type TagsCellProps = {
  row: Row<{ tags: TTag[] }>;
};
export function TagsCell({ row }: TagsCellProps) {
  const tags: TTag[] = row.original?.tags;
  const tagsName = _.uniqBy(tags, 'id').map((item: TTag) => (
    <Typography
      key={item.id}
      className="text-13 font-400 capitalize "
    >
      {item.name}
    </Typography>
  ));
  return <div>{tagsName}</div>;
}

type StatusCellProps = {
  cell: Cell<{ value: boolean }>;
};
export function StatusCell({ cell }: StatusCellProps) {
  return cell.value ? 'Active' : 'Inactive';
}
