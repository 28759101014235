import clsx from 'clsx';
import React from 'react';

import FuseNavigation from '@fuse/core/FuseNavigation';

type Props = {
  className?: string;
  navigation: any[];
  layout?: string;
  dense?: boolean;
  active?: boolean;
};

function Navigation(props: Props) {
  return (
    <FuseNavigation
      className={clsx('navigation', props.className)}
      navigation={props.navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
    />
  );
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default React.memo(Navigation);
