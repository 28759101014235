import { submitLogin } from 'app/auth/store/loginSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

function JWTLoginTab(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const formRef = useRef(null);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (login.error && (login.error.email || login.error.password)) {
      formRef.current.updateInputsWithError({
        ...login.error,
      });
      disableButton();
    }
  }, [login.error]);

  function disableButton() {}

  function enableButton() {}

  function onSubmit(model) {
    dispatch(submitLogin(model));
  }

  return (
    <div className="w-full pb-24 text-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center w-full"
      >
        <TextField
          name="email"
          type="text"
          placeholder="Email"
          {...register('email')}
          className="mb-16 bg-white rounded-4"
          inputProps={{
            className: 'text-center',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  className="text-20"
                  color="action"
                >
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <div className="invalid-feedback">{errors.email?.message}</div>

        <TextField
          name="password"
          type="password"
          placeholder="Password"
          className="mb-16 bg-white rounded-4"
          inputProps={{
            className: 'text-center',
          }}
          InputProps={{
            className: 'pr-2',
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <Icon
                    className="text-20"
                    color="action"
                  >
                    {showPassword ? 'visibility' : 'visibility_off'}
                  </Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          required
          {...register('password')}
        />
        <div className="invalid-feedback">{errors.password?.message}</div>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16 normal-case"
          classes={{
            root: classes.root,
          }}
          aria-label="LOG IN"
          value="legacy"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
          Login
        </Button>
      </form>
    </div>
  );
}

export default JWTLoginTab;
