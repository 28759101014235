import { useFetchTags } from 'app/hooks/react-query/tags';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { TArticleTagAttribute, TTag } from 'models';

import Typography from '@mui/material/Typography';

type Tag = TTag | TArticleTagAttribute;

type Props<T> = {
  selectedTag?: T | null;
  readOnly?: boolean;
  onSelect?: (selectedTag: T) => void;
};

function Tags<T extends Tag>({ selectedTag, readOnly, onSelect }: Props<T>) {
  const { data: dataTags } = useFetchTags({ per_page: 20 });
  const tags = dataTags?.records || [];

  const handleSelect = (tag: T) => () => {
    if (readOnly) return;
    onSelect?.(tag);
  };

  if (!tags?.length) return null;

  return (
    <div className="px-24">
      <div className="flex items-center justify-between mb-16">
        <Typography className="text-11 font-400 uppercase">Tags</Typography>
      </div>

      <div>
        {tags?.map((tag: any) => (
          <motion.div
            key={tag.id}
            tabIndex={0}
            role="button"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.5 },
            }}
            whileTap={{ scale: 0.9 }}
            className={clsx(
              'shadow rounded-24 px-12 lg:px-16 h-24 lg:h-32 inline-flex items-center justify-center mr-8 mb-8 max-w-136',
              tag.id === selectedTag?.id ? 'bg-warning' : 'bg-white',
            )}
            onClick={handleSelect(tag)}
          >
            <Typography
              className="text-11 lg:text-13 font-600 truncate"
              title={tag.name || tag.tagName}
            >
              {tag.name || tag.tagName}
            </Typography>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Tags;
