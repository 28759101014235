import HeaderContainer from 'app/components/Layout/headers/header-container';
import EditorViewer from 'app/components/cores/editor-js/editor-viewer';
import Loading from 'app/components/cores/loading';
import LatestNews from 'app/components/news/latest-news';
import Tags from 'app/components/news/tags';
import TagItem from 'app/components/tags/item';
import { useArticle } from 'app/hooks/react-query/employee';
import { Paths } from 'constants/paths';
import { TArticleTagAttribute, TTag } from 'models';
import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';

import BluefitPage from '@fuse/core/BluefitPage';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

interface ParamsTypes {
  articleSlug: string;
}

function Detail() {
  const history = useHistory();
  const routeParams = useParams<ParamsTypes>();
  const { articleSlug } = routeParams;

  const { data, isLoading } = useArticle(articleSlug);
  const image = data?.media?.objectFiles?.[0]?.url;

  const handleBackClick = () => {
    history.goBack();
  };

  const handleSelectTag = (tag: TTag) => {
    const searchString = queryString.stringify({
      'q[tags_id_eq]': tag.id,
      'q[tags_name_eq]': tag.name,
    });

    history.push({
      pathname: Paths.news,
      search: searchString,
    });
  };

  return (
    <BluefitPage
      isNewLayout={true}
      classes={{
        contentWrapper: 'pt-24',
        content: 'flex flex-col sm:flex-row',
        leftSidebar: 'w-256 border-0',
        wrapper: isLoading ? 'bg-white' : 'bg-paper',
      }}
      header={
        <HeaderContainer>
          <div
            tabIndex={0}
            role="button"
            className="flex items-center"
            onClick={handleBackClick}
          >
            <div className="w-40 h-40 flex items-center justify-center lg:mr-24">
              <ArrowBackIosIcon color="secondary" />
            </div>
            <Typography className="text-15 font-600 uppercase">All News</Typography>
          </div>
        </HeaderContainer>
      }
      rightSidebar={
        <>
          <Tags onSelect={handleSelectTag} />
          <LatestNews className="mt-16 hidden sm:block" />
        </>
      }
      content={
        <>
          <Fade
            in={!isLoading}
            timeout={1000}
          >
            <div className="pl-24 pr-24 sm:pr-0 h-full">
              <div className="bg-white shadow h-full">
                <div className="lg:px-80 lg:pt-80 mb-24">
                  {/* primary image */}
                  {image && (
                    <img
                      alt="cover"
                      src={image}
                      className="w-full object-cover"
                    />
                  )}
                </div>

                <div className="px-24 pb-24 lg:px-80 lg:pb-80">
                  {/* content */}
                  <Typography className="text-24 lg:text-40 font-700 uppercase pb-24">{data?.title}</Typography>

                  {/* tags */}
                  <div className="mb-16">
                    {data?.articleTagsAttributes?.map((tag: TArticleTagAttribute) => (
                      <TagItem
                        key={tag.id}
                        item={tag}
                      />
                    ))}
                  </div>

                  {/* date */}
                  <div className="mb-16">
                    <Typography className="text-11 font-500 text-secondaryLight">
                      {formatDate(data?.publishedAt, 'DD.MM.YYYY')}
                    </Typography>
                  </div>

                  {/* content */}
                  <EditorViewer content={data?.content} />
                </div>
              </div>
            </div>
          </Fade>
          <Loading visible={isLoading} />
        </>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default Detail;
