import Select from 'app/components/cores/select';
import { useFetchEmployeeLocations } from 'app/hooks/react-query/employee';
import useSelect from 'app/hooks/use-select';
import { TLocation } from 'models';
import { useState } from 'react';

import { useTheme } from '@mui/material';

type Props = {
  location?: TLocation | null;
  className?: string;
  onChange?: (location: any) => void;
};

function LocationDropdown({ location, className, onChange }: Props) {
  const theme = useTheme();
  const selectStyles = generateSelectStyles(theme);

  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(200);

  const { data, isLoading } = useFetchEmployeeLocations({
    page: pageIndex,
    per_page: pageSize,
    search: searchText,
    with_salt: false,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const handleChange = (item: any) => {
    if (!item) {
      return onChange?.({
        id: 0,
        name: 'All Locations',
      });
    }

    onChange?.(item);
  };

  return (
    <div className={className}>
      <Select
        isClearable={!!location?.id}
        value={location}
        options={[{ id: 0, name: 'All Locations' }, ...options]}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        menuPortalTarget={document.querySelector('#articles') as any}
        menuPosition="fixed"
        styles={selectStyles}
        onInputChange={handleSearch}
        onMenuScrollToBottom={handleLoadMore}
        onChange={handleChange}
      />
    </div>
  );
}

const generateSelectStyles = (theme: any) => ({
  control: (provided: any) => ({
    ...provided,
    minHeight: 40,
    borderColor: theme.palette.primary.main,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: theme.palette.primary.main,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: theme.palette.primary.main,
    paddingRight: 0,
  }),
});

export default LocationDropdown;
