import { useQuery } from 'react-query';
import api from 'utils/api';

export const locationCategoryKeys = {
  all: ['locationCategories'] as const,
  lists: () => [...locationCategoryKeys.all, 'list'] as const,
  list: (filters: any) => [...locationCategoryKeys.lists(), { filters }] as const,
  details: () => [...locationCategoryKeys.all, 'detail'] as const,
  detail: (id: number) => [...locationCategoryKeys.details(), id] as const,
};

async function fetchLocationCategories(filters: any) {
  const res = await api.get('/api/location_categories', { params: filters });
  return res.data;
}

export function useFetchLocationCategories(filters: any) {
  return useQuery([...locationCategoryKeys.list(filters)], () => fetchLocationCategories(filters));
}
