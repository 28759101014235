import Header from 'app/components/Layout/headers/blank-header-v2';
import ArticleForm from 'app/components/articles/form';
import ConfirmDialog from 'app/components/cores/confirm-dialog';
import ControlTab from 'app/components/cores/control-tab';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import Loading from 'app/components/cores/loading';
import { useArticle, useUpdateArticle } from 'app/hooks/react-query/articles';
import useShowMessage from 'app/hooks/use-show-message';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { formatInputAttributes, formatInputMedia, formatOutputAttributes } from 'domains/articles.domain';
import { showFormError } from 'domains/form.domain';
import { TArticle, TError } from 'models';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fade, Icon, Tabs, Typography } from '@mui/material';

const schema = yup
  .object({
    title: yup.string().required('Please enter the article title'),
    content: yup.object().nullable(true),
    mediaAttributes: yup.object({
      id: yup.number(),
    }),
    articleTagsAttributes: yup.array().of(
      yup.object({
        tagId: yup.number(),
      }),
    ),
    articleRolesAttributes: yup.array().of(
      yup.object({
        roleId: yup.number(),
      }),
    ),
    articleLocationsAttributes: yup.array().of(
      yup.object({
        locationId: yup.number(),
      }),
    ),
  })
  .required();

interface ParamsTypes {
  articleId: string;
}

function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const routeParams = useParams<ParamsTypes>();
  const { articleId } = routeParams;
  const { data: article, isLoading: isGettingArticle } = useArticle(Number(articleId));
  const { mutate: updateArticle, isLoading: isUpdatingArticle, error } = useUpdateArticle(Number(articleId));
  const { showError, showSuccess } = useShowMessage();

  const [tabValue, setTabValue] = useState(0);
  const form = useForm({ resolver: yupResolver(schema) });
  const isDraft = article?.status === 'draft';
  const isInactive = article?.status === 'inactive';

  const isInactiveArticle = isDraft || isInactive;

  const isCreatedArticle = !!article?.id;
  const isLoading = isGettingArticle || isUpdatingArticle;
  const articlePreviewData = form?.getValues() as TArticle;

  // set default form value
  useEffect(() => {
    if (!article?.id) return;

    const {
      title,
      content,
      media,
      articleTagsAttributes,
      articleRolesAttributes,
      articleLocationsAttributes,
      articleLocationCategoriesAttributes,
    } = article;

    form.setValue('title', title);
    form.setValue('content', content);
    form.setValue('mediaAttributes', formatInputMedia(media));
    form.setValue('articleTagsAttributes', formatInputAttributes(articleTagsAttributes, ['tagId', 'tagName']));
    form.setValue('articleRolesAttributes', formatInputAttributes(articleRolesAttributes, ['roleId', 'roleName']));
    form.setValue(
      'articleLocationsAttributes',
      formatInputAttributes(articleLocationsAttributes, ['locationId', 'locationName']),
    );
    form.setValue(
      'articleLocationCategoriesAttributes',
      formatInputAttributes(articleLocationCategoriesAttributes, ['locationCategoryId', 'locationCategoryName']),
    );
  }, [article, form]);

  useEffect(() => {
    const errorMessage = form.formState.errors?.title?.message;
    if (!errorMessage) return;
    showError(errorMessage);
  }, [form.formState.errors, showError]);

  useEffect(() => {
    showFormError(form, error as TError);
  }, [form, error]);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    event.preventDefault();
    setTabValue(value);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = (data: any) => {
    save(data);
  };

  const handlePublish = (data: any) => {
    save(data, 'published');
  };

  const handleDeactivate = (data: any) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Unpublish Article"
            message="Are you sure you want to unpublish this article?"
            confirmButtonLabel="Unpublish"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              save(data, 'inactive');
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };

  const save = (data: any, status?: 'draft' | 'published' | 'inactive') => {
    const {
      articleTagsAttributes,
      articleRolesAttributes,
      articleLocationsAttributes,
      articleLocationCategoriesAttributes,
      ...rest
    } = data;
    const payload = {
      ...rest,
      status,
      articleTagsAttributes: formatOutputAttributes(articleTagsAttributes, 'tagId'),
      articleRolesAttributes: formatOutputAttributes(articleRolesAttributes, 'roleId'),
      articleLocationsAttributes: formatOutputAttributes(articleLocationsAttributes, 'locationId'),
      articleLocationCategoriesAttributes: formatOutputAttributes(
        articleLocationCategoriesAttributes,
        'locationCategoryId',
      ),
    };

    updateArticle(payload, {
      onSuccess() {
        showSuccess('Article has been successfully updated');
      },
      onError() {
        showError('Could not update article');
      },
    });
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
        wrapper: 'relative',
        content: clsx(tabValue === 0 ? 'bg-white' : 'bg-paper'),
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <Typography className="ml-16 text-18 font-600 line-clamp-1">{article?.title}</Typography>
            </div>
          }
          rightHeader={
            <div className="flex flex-row">
              {isLoading ? (
                <div>
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap"
                    onClick={handleCancel}
                    btnTitle="Cancel"
                  />
                  {!isInactiveArticle && (
                    <HeadButtonRight
                      className="ml-4 whitespace-no-wrap"
                      variant="outlined"
                      onClick={form.handleSubmit(handleDeactivate)}
                      btnTitle="Unpublish"
                    />
                  )}
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant={isInactiveArticle ? 'outlined' : 'contained'}
                    onClick={form.handleSubmit(handleSave)}
                    btnTitle="Save"
                  />
                  {isInactiveArticle && (
                    <HeadButtonRight
                      className="ml-4 whitespace-no-wrap"
                      variant="contained"
                      onClick={form.handleSubmit(handlePublish)}
                      btnTitle="Publish"
                    />
                  )}
                </div>
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full h-48 relative' }}
        >
          <ControlTab
            className="h-48 normal-case font-400"
            label="Editor"
          />
          <ControlTab
            className="h-48 normal-case font-400"
            label="Settings"
          />
          <ControlTab
            className="h-48 normal-case font-400"
            label="Preview"
          />
        </Tabs>
      }
      content={
        <>
          <FormProvider {...form}>
            <Fade
              in={isCreatedArticle}
              timeout={1000}
              className="h-full"
            >
              <div>
                <ArticleForm
                  tabIndex={tabValue}
                  isCreatedArticle={isCreatedArticle}
                  article={articlePreviewData}
                />
              </div>
            </Fade>
          </FormProvider>
          <Loading
            visible={isLoading}
            opacity={isUpdatingArticle}
          />
        </>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default Edit;
