import { useQuery } from 'react-query';
import api from 'utils/api';

export const roleKeys = {
  all: ['roles'] as const,
  lists: () => [...roleKeys.all, 'list'] as const,
  list: (filters: any) => [...roleKeys.lists(), { filters }] as const,
  details: () => [...roleKeys.all, 'detail'] as const,
  detail: (id: number) => [...roleKeys.details(), id] as const,
};

async function fetchRoles(filters: any) {
  const res = await api.get('/api/roles', { params: filters });
  return res.data;
}

export function useFetchRoles(filters: any) {
  return useQuery([...roleKeys.list(filters)], () => fetchRoles(filters));
}
