import React, { useEffect, useRef, useState } from 'react';
import api from 'utils/api';

import { CircularProgress, Typography } from '@mui/material';

type Props = {
  isLoading?: boolean;
  isLoadedContent?: boolean;
  children: React.ReactNode;
  onSetToken: (isSuccess: boolean) => void;
};

function PublicContentContainer({ isLoading, isLoadedContent, children, onSetToken }: Props) {
  const [hasToken, setHasToken] = useState(false);
  const retry = useRef(0);
  const retries = useRef(20).current;
  const isError = retry.current >= retries && !hasToken;

  const getToken = () => {
    const tokenElement = document.getElementById('token');
    return tokenElement?.textContent;
  };

  const isSetToken = () => {
    const accessToken = getToken();
    const hasToken = !!accessToken;

    if (hasToken) {
      setHasToken(hasToken);
      api.defaults.headers.common.Authorization = `SBOX ${accessToken}`;
    }
    return hasToken;
  };

  useEffect(() => {
    if (isSetToken()) return onSetToken?.(true);

    const interval = setInterval(() => {
      if (isSetToken()) {
        onSetToken?.(true);
        clearInterval(interval);
      }
      //
      else if (retry.current >= retries) {
        onSetToken?.(false);
        clearInterval(interval);
      }

      retry.current += 1;
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full h-full bg-white overflow-auto p-24">
      <div
        id="token"
        className="hidden"
      />
      {isError && <Typography className="text-15 font-400 mt-32 text-center">Failed to load content</Typography>}
      {isLoading && (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress size={32} />
        </div>
      )}
      {isLoadedContent && <>{children}</>}
    </div>
  );
}

export default PublicContentContainer;
