import Columns from '@calumk/editorjs-columns';
import _ from '@lodash';

class CustomColumnsTool extends Columns {
  async save() {
    const newData = _.cloneDeep(this.data) || {};
    if (!this.readOnly) {
      for (let index = 0; index < this.editors.cols.length; index++) {
        const save = _.get(this.editors.cols[index], 'save');
        // eslint-disable-next-line
        const colData = await save?.();
        newData.cols[index] = colData || {};
      }
    }

    return newData;
  }
}

export default CustomColumnsTool;
