/* eslint-disable */
import NewsIcon from 'app/components/cores/icons/news';
import React from 'react';

import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

type Props = {
  foldedAndClosed: boolean;
  currentRole: string;
  show?: boolean;
  onNavigationSwitch: (role: string) => void;
};

function NavigationSwitch(props: Props) {
  const { foldedAndClosed, onNavigationSwitch, currentRole, show } = props;

  if (!show) return null;

  const isActiveByRole = (role: string) => {
    return role === currentRole;
  };

  return (
    <div>
      {foldedAndClosed ? (
        <AppBar
          position="static"
          color="secondary"
          elevation={0}
          className="user relative flex flex-col z-0"
        >
          <div
            className="flex-auto flex justify-center"
            onClick={() => onNavigationSwitch('my_news')}
          >
            <div
              className={`flex flex-col items-center justify-center text-13 h-64 w-40 border-white ${
                isActiveByRole('my_news') ? 'border-b-2' : ''
              }`}
            >
              <NewsIcon className="text-20 text-white" />
            </div>
          </div>
          <div
            className="flex-auto flex justify-center"
            onClick={() => onNavigationSwitch('editor')}
          >
            <div
              className={`flex flex-col items-center justify-center text-13 h-64 w-40 border-white ${
                isActiveByRole('editor') ? 'border-b-2' : ''
              }`}
            >
              <Icon className="text-20">supervisor_account</Icon>
            </div>
          </div>
        </AppBar>
      ) : (
        <AppBar
          position="static"
          color="secondary"
          elevation={0}
          className="user relative flex flex-row z-0 h-64 cursor-pointer"
        >
          <div
            className="flex-auto flex justify-center"
            onClick={() => onNavigationSwitch('my_news')}
          >
            <div
              className={`flex flex-col items-center justify-center w-72 h-full border-white ${
                isActiveByRole('my_news') ? 'border-b-2' : ''
              }`}
            >
              <NewsIcon className="text-20 text-white" />
              <Typography className="uppercase text-13 pt-8">My News</Typography>
            </div>
          </div>
          <div
            className="flex-auto flex justify-center"
            onClick={() => onNavigationSwitch('editor')}
          >
            <div
              className={`flex flex-col items-center justify-center w-72 h-full border-white ${
                isActiveByRole('editor') ? 'border-b-2' : ''
              }`}
            >
              <Icon className="text-20">supervisor_account</Icon>
              <Typography className="uppercase text-13 pt-8">Editor</Typography>
            </div>
          </div>
        </AppBar>
      )}
    </div>
  );
}

export default NavigationSwitch;
