import clsx from 'clsx';
import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Menu from './Menu';

const useStyles = makeStyles(() => ({
  rotate180: {
    transform: 'rotate(180deg)',
  },
  menuPaper: {
    top: '65px !important',
    left: '0px !important',
    right: '0px !important',
    maxWidth: 'unset',
    borderRadius: 0,
    boxShadow: 'none',
    '&  a': {
      marginBottom: 8,
    },
  },
}));

type Props = {
  items?: any;
};

function TopBarMobile({ items }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'menu' : undefined;

  const handleShowMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHideMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="bg-white h-64 items-center px-24 flex">
        <div
          role="button"
          tabIndex={0}
          className="flex items-center px-24"
          onClick={handleShowMenu}
        >
          <Typography className="text-11 font-400 uppercase">Services</Typography>
          <KeyboardArrowDownIcon className={clsx('text-16 ml-4', !!open && classes.rotate180)} />
        </div>
      </div>

      <Divider className="bg-gray-100" />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleHideMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: clsx('border-b bg-white pt-20 pb-8', classes.menuPaper),
        }}
      >
        <div>
          <Menu
            items={items}
            isDesktopScreen={false}
          />
        </div>
      </Popover>
    </>
  );
}

export default TopBarMobile;
