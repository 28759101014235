import bluefitSSOService from 'app/services/bluefitSSOService';
import { showMessage } from 'app/store/fuse/messageSlice';
import { setDefaultSettings, setInitialSettings } from 'app/store/fuse/settingsSlice';
import { DEFAULT_MAIN_COLOR } from 'constants/index';

import history from '@history';
import _ from '@lodash';
import { createSlice } from '@reduxjs/toolkit';

export const setUserData = user => async (dispatch, getState) => {
  const userRoles = !!user?.roles?.length ? ['editor'] : ['employee'];

  const brandPrimaryColor = user.brandPrimaryColor ? user.brandPrimaryColor : DEFAULT_MAIN_COLOR;

  const tmpUser = {
    data: {
      accessRoles: user.accessRoles,
      email: user.email,
      displayName: user.fullName,
      settings: {
        theme: {
          main: 'default',
        },
      },
    },
    brandPrimaryColor,
    brandLogo: user.brandLogo,
    brandBrandInitial: user.brandBrandInitial,
    brandLogoSymbol: user.brandLogoSymbol,
    role: userRoles,
    redirectUrl: '/',
    mobileServices: user?.mobileServices || [],
  };

  history.location.state = {
    redirectUrl: tmpUser.redirectUrl,
  };

  dispatch(setDefaultSettings(tmpUser.data.settings));
  dispatch(setUser(tmpUser));
};

export const updateUserSettings = settings => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = () => async dispatch => {
  bluefitSSOService.logout();

  dispatch(setInitialSettings());

  dispatch(userLoggedOut());
  return null;
};

export const updateUserData = user => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
    default: {
      bluefitSSOService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch(error => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
  }
};

const initialState = {
  role: [], // guest
  data: {},
  brandPrimaryColor: DEFAULT_MAIN_COLOR,
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
