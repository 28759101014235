import { useQuery } from 'react-query';
import api from 'utils/api';

export const serviceKeys = {
  all: ['services'] as const,
  lists: () => [...serviceKeys.all, 'list'] as const,
  list: (filters: any) => [...serviceKeys.lists(), { filters }] as const,
  details: () => [...serviceKeys.all, 'detail'] as const,
  detail: (id: number) => [...serviceKeys.details(), id] as const,
};

async function fetchServices() {
  try {
    const response = await api.get('/api/services');
    return response?.data?.services || [];
  } catch (_) {
    return [];
  }
}

export function useFetchServices() {
  return useQuery([...serviceKeys.lists()], () => fetchServices());
}
